const MainSiteLogoSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="760px"
      height="760px"
      viewBox="0 0 760 760"
    >
      <g>
        <path
          className="st0"
          fill="#ffffff"
          d="M101.6,467.5c16.6,0,27.6-9.2,27.6-25.4v-0.3c0-16.1-11-25.4-27.6-25.4H90.3v51.1L101.6,467.5z M62.6,391.7
		h38.7c35.8,0,56.6,20.7,56.6,49.7v0.3c0,29-21,50.5-57.1,50.5H62.6V391.7z"
        />
      </g>
      <path
        className="st0"
        fill="#ffffff"
        d="M223.9,452.6L212.7,424l-11.3,28.6H223.9z M199.6,391h26.8L269,492.2h-29.8l-7.3-18h-38.7l-7.2,18h-29.2
	L199.6,391z"
      />
      <polygon
        className="st0"
        fill="#ffffff"
        points="290.3,416.1 260.3,416.1 260.3,391.7 348.2,391.7 348.2,416.1 318.1,416.1 318.1,492.2 290.3,492.2 "
      />
      <path
        className="st0"
        fill="#ffffff"
        d="M406.4,452.6L395.3,424l-11.3,28.6H406.4z M382.1,391h26.8l42.7,101.3h-29.8l-7.3-18h-38.7l-7.2,18h-29.2
	L382.1,391z"
      />
      <g>
        <path
          className="st0"
          fill="#ffffff"
          d="M51,605l15.5-18.5c9.1,7.7,20.6,11.9,32.5,12.1c7.4,0,11.5-2.6,11.5-6.9v-0.3c0-4.2-3.3-6.5-16.9-9.6
		c-21.3-4.9-37.8-10.9-37.8-31.6v-0.3c0-18.7,14.7-32.2,38.8-32.2c14.9-0.6,29.5,4.2,41.2,13.4l-13.9,19.7
		c-8.1-6.1-17.9-9.5-28.1-9.9c-6.7,0-10,2.9-10,6.5v0.3c0,4.6,3.4,6.6,17.3,9.8c23,5,37.4,12.5,37.4,31.3v0.3
		c0,20.5-16.2,32.7-40.5,32.7C80.8,622.1,64.1,616.2,51,605"
        />
        <path
          className="st0"
          fill="#ffffff"
          d="M148.5,570v-0.3c-0.4-28.5,22.4-51.9,50.8-52.3c0.7,0,1.3,0,2,0c16.8-0.8,32.9,7.1,42.5,21L223,554.6
		c-4.9-7.3-13.1-11.7-21.9-11.8c-14,0-23.9,11.9-23.9,26.6v0.3c0,15.1,9.9,26.9,23.9,26.9c10.4,0,16.6-4.9,22.6-12.2l20.9,14.9
		c-16.6,24-49.5,29.9-73.4,13.2C157.2,602.8,148.8,587,148.5,570L148.5,570z"
        />
      </g>
      <rect
        x="257"
        y="519.4"
        className="st0"
        fill="#ffffff"
        width="27.9"
        height="100.5"
      />
      <polygon
        className="st0"
        fill="#ffffff"
        points="303.3,519.4 383.9,519.4 383.9,543.1 330.8,543.1 330.8,558.3 378.9,558.3 378.9,580.3 330.8,580.3 
	330.8,596.2 384.6,596.2 384.6,619.9 303.3,619.9 "
      />
      <polygon
        className="st0"
        fill="#ffffff"
        points="398.8,519.4 424.7,519.4 465.9,572.5 465.9,519.4 493.4,519.4 493.4,619.9 469,619.9 426.2,564.8 
	426.2,619.9 398.7,619.9 "
      />
      <g>
        <path
          className="st0"
          fill="#ffffff"
          d="M507.7,570v-0.3c-0.4-28.5,22.4-51.9,50.8-52.3c0.7,0,1.3,0,2,0c16.8-0.8,32.9,7.1,42.5,21l-20.9,16.2
		c-4.9-7.3-13.1-11.7-21.9-11.8c-14,0-23.9,11.9-23.9,26.6v0.3c0,15.1,9.9,26.9,23.9,26.9c10.5,0,16.6-4.9,22.6-12.2l20.9,14.9
		c-16.6,24-49.5,29.9-73.4,13.2C516.4,602.8,508,587,507.7,570z"
        />
      </g>
      <polygon
        className="st0"
        fill="#ffffff"
        points="615.6,519.4 696.3,519.4 696.3,543.1 643.1,543.1 643.1,558.3 691.2,558.3 691.2,580.3 643.1,580.3 
	643.1,596.2 697,596.2 697,619.9 615.6,619.9 "
      />
      <g>
        <path
          className="st0"
          fill="#ffffff"
          d="M126.2,194.7H85v42.6c0,0.9,0.1,1.8,0.3,2.7c0.2,0.7,0.6,1.3,1.2,1.6c0.9,0.4,1.9,0.7,2.9,0.8
		c1.3,0.2,3,0.4,5.1,0.6v1.4H55.2v-1.4c2.1-0.2,3.8-0.4,5.1-0.6c1-0.1,2-0.4,2.9-0.8c0.6-0.3,1.1-0.9,1.2-1.6
		c0.2-0.9,0.3-1.8,0.3-2.7v-83.8c0-0.9-0.1-1.8-0.3-2.7c-0.2-0.7-0.6-1.3-1.2-1.6c-0.9-0.5-1.9-0.7-2.9-0.8c-1.3-0.2-3-0.4-5.1-0.6
		v-1.4h39.3v1.4c-2.1,0.2-3.8,0.4-5.1,0.6c-1,0.1-2,0.4-2.9,0.8c-0.6,0.3-1.1,0.9-1.2,1.6c-0.2,0.9-0.3,1.8-0.3,2.7v39.8h41.2v-39.8
		c0-0.9-0.1-1.8-0.3-2.7c-0.2-0.7-0.6-1.3-1.2-1.6c-0.9-0.5-1.9-0.7-2.9-0.8c-1.3-0.2-3-0.4-5.1-0.6v-1.4H156v1.4
		c-2.2,0.2-3.9,0.4-5.1,0.6c-1,0.1-2,0.4-2.9,0.8c-0.6,0.3-1.1,0.9-1.2,1.6c-0.2,0.9-0.3,1.8-0.3,2.7v83.8c0,0.9,0.1,1.8,0.3,2.7
		c0.2,0.7,0.6,1.3,1.2,1.6c0.9,0.5,1.9,0.7,2.9,0.8c1.3,0.2,3,0.4,5.1,0.6v1.4h-39.3v-1.4c2.1-0.2,3.9-0.4,5.1-0.6
		c1-0.1,2-0.4,2.9-0.8c0.6-0.3,1.1-0.9,1.2-1.6c0.2-0.9,0.3-1.8,0.3-2.7V194.7z"
        />
        <path
          className="st0"
          fill="#ffffff"
          d="M173.2,153.5c0-0.9-0.1-1.8-0.3-2.7c-0.2-0.7-0.6-1.3-1.3-1.6c-0.9-0.4-1.9-0.7-2.9-0.8
		c-1.3-0.2-3-0.4-5.1-0.6v-1.4H203v1.4c-2.1,0.2-3.8,0.4-5.1,0.6c-1,0.1-2,0.4-2.9,0.8c-0.6,0.3-1.1,0.9-1.2,1.6
		c-0.2,0.9-0.3,1.8-0.3,2.7v83.8c0,0.9,0.1,1.8,0.3,2.7c0.2,0.7,0.6,1.3,1.2,1.6c0.9,0.5,1.9,0.7,2.9,0.8c1.3,0.2,3,0.4,5.1,0.6v1.4
		h-39.3v-1.4c2.1-0.2,3.9-0.4,5.1-0.6c1-0.1,2-0.4,2.9-0.8c0.6-0.3,1.1-0.9,1.3-1.6c0.2-0.9,0.3-1.8,0.3-2.7V153.5z"
        />
        <path
          className="st0"
          fill="#ffffff"
          d="M272.9,175.4c-1.1-3.8-2.5-7.6-4.2-11.1c-1.6-3.3-3.6-6.4-5.9-9.3c-2.1-2.5-4.7-4.7-7.6-6.3
		c-2.7-1.5-5.8-2.3-8.9-2.3c-4.2-0.1-8.3,1.1-11.7,3.5c-3.3,2.5-5,6.5-4.8,10.7c-0.1,3.8,1.1,7.6,3.6,10.6c2.6,3,5.6,5.6,8.9,7.8
		c3.8,2.4,7.7,4.7,11.7,6.7c4.1,2.1,8,4.5,11.7,7.3c3.5,2.6,6.5,5.9,8.9,9.5c2.5,4,3.7,8.7,3.6,13.5c0.1,4.5-0.8,9.1-2.7,13.2
		c-1.7,3.6-4.3,6.8-7.4,9.3c-3.3,2.6-7,4.5-11,5.6c-4.3,1.3-8.8,1.9-13.3,1.9c-2.6,0-5.2-0.2-7.8-0.5c-2.7-0.3-5.3-0.8-7.9-1.4
		c-2.5-0.6-5-1.3-7.4-2.2c-2.2-0.8-4.3-1.8-6.4-2.9l-1.4-25.5h1.4c1.5,8.6,5.3,16.5,11.1,23c4.8,5.2,11.6,8.2,18.7,8.1
		c2.3,0,4.5-0.4,6.7-1.1c2.1-0.7,4-1.9,5.6-3.4c1.7-1.6,3-3.5,3.8-5.7c1-2.6,1.5-5.3,1.5-8.1c0.1-4-1.1-7.9-3.6-11
		c-2.5-3.1-5.6-5.8-9-7.9c-3.7-2.4-7.6-4.6-11.7-6.6c-4.1-2-8-4.3-11.7-6.9c-3.5-2.4-6.5-5.5-8.9-9c-2.5-3.8-3.7-8.2-3.6-12.8
		c-0.1-3.9,0.8-7.8,2.4-11.4c1.6-3.3,3.9-6.3,6.8-8.6c3.1-2.4,6.7-4.3,10.5-5.4c4.3-1.3,8.7-1.9,13.2-1.9c4.6,0,9.2,0.6,13.6,1.5
		c4.6,0.9,9,2.5,13.1,4.8l1.4,24.1L272.9,175.4z"
        />
        <path
          className="st0"
          fill="#ffffff"
          d="M286.8,146.4h75.6l0.7,23.7h-1.4c-1-6.1-3.6-11.7-7.6-16.3c-3.4-3.8-8.2-6-13.4-6h-6v89.6
		c0,0.9,0.1,1.8,0.3,2.7c0.2,0.7,0.6,1.3,1.2,1.6c0.9,0.4,1.9,0.7,2.9,0.8c1.3,0.2,3,0.4,5.1,0.6v1.4H305v-1.4
		c2.1-0.2,3.8-0.4,5.1-0.6c1-0.1,2-0.4,2.9-0.8c0.6-0.3,1.1-0.9,1.2-1.6c0.2-0.9,0.3-1.8,0.3-2.7v-89.6h-6c-5.1,0-10,2.1-13.4,6
		c-4,4.6-6.6,10.3-7.6,16.3h-1.4L286.8,146.4z"
        />
        <path
          className="st0"
          fill="#ffffff"
          d="M417.4,146.4c-4.4-0.1-8.7,1.2-12.3,3.8c-3.7,2.7-6.8,6.3-8.9,10.4c-2.6,4.9-4.4,10.1-5.5,15.6
		c-2.5,12.8-2.5,25.9,0,38.7c1.1,5.4,2.9,10.7,5.5,15.6c2.1,4.1,5.2,7.7,8.9,10.4c7.4,5,17.1,5,24.5,0c3.7-2.7,6.8-6.3,8.9-10.4
		c2.6-4.9,4.4-10.1,5.5-15.6c2.5-12.8,2.5-25.9,0-38.7c-1.1-5.4-2.9-10.7-5.5-15.6c-2.1-4.1-5.2-7.7-8.9-10.4
		C426,147.6,421.8,146.3,417.4,146.4 M417.4,245.9c-6.6,0-13.1-1.2-19.2-3.7c-5.9-2.4-11.3-5.9-15.9-10.4c-4.6-4.6-8.3-10-10.8-16
		c-5.4-13-5.4-27.6,0-40.7c2.6-6,6.2-11.4,10.8-16c4.5-4.5,9.9-8.1,15.9-10.4c12.3-5,26-5,38.3,0c5.9,2.4,11.3,5.9,15.9,10.4
		c4.6,4.6,8.3,10,10.8,16c5.4,13,5.4,27.6,0,40.7c-2.6,6-6.2,11.4-10.8,16c-4.5,4.5-9.9,8.1-15.9,10.4
		C430.5,244.7,424,245.9,417.4,245.9"
        />
        <path
          className="st0"
          fill="#ffffff"
          d="M506.8,147.8v50.5h6.8c3.3,0.1,6.6-0.5,9.6-1.9c2.4-1.2,4.4-3,5.8-5.2c1.5-2.4,2.4-5,2.9-7.8
		c1.1-6.4,1.1-12.9,0-19.3c-0.5-2.9-1.4-5.7-2.7-8.3c-1.2-2.3-3-4.3-5.1-5.8c-2.4-1.6-5.1-2.3-8-2.2L506.8,147.8z M521.2,146.4
		c4.7-0.1,9.4,0.6,14,2c3.8,1.1,7.3,3,10.3,5.5c2.7,2.3,4.9,5.2,6.4,8.5c1.5,3.4,2.3,7,2.2,10.7c0,3.4-0.7,6.7-2,9.7
		c-1.4,3.2-3.6,6.1-6.4,8.4c-3.3,2.6-7,4.7-11,6c-5.2,1.7-10.6,2.5-16,2.6c4.3,0.6,8.4,1.7,12.5,3.2c2.8,1.1,5.5,2.4,8,4.1
		c1.8,1.2,3.4,2.8,4.8,4.6c1,1.4,2,2.9,2.8,4.5l9.6,17.2c1.3,2.8,3.2,5.2,5.5,7.3c1.7,1.3,3.7,2.2,5.8,2.5v1.4h-27.1
		c-1.5-2.2-3-4.8-4.6-7.6c-1.5-2.7-2.9-5.4-4.1-8.3l-7.4-15.3c-1.4-2.8-2.9-5.5-4.5-8.1c-1.4-2.3-3.3-4.3-5.6-5.7h-7.8v37.7
		c0,0.9,0.1,1.8,0.3,2.7c0.2,0.7,0.6,1.3,1.2,1.6c0.9,0.4,1.9,0.7,2.9,0.8c1.3,0.2,3,0.4,5.1,0.6v1.4H477v-1.4
		c2.1-0.2,3.8-0.4,5.1-0.6c1-0.1,2-0.4,2.9-0.8c0.6-0.3,1.1-0.9,1.2-1.6c0.2-0.9,0.3-1.8,0.3-2.7v-83.8c0-0.9-0.1-1.8-0.3-2.7
		c-0.2-0.7-0.6-1.3-1.2-1.6c-0.9-0.4-1.9-0.7-2.9-0.8c-1.3-0.2-3-0.4-5.1-0.6v-1.4L521.2,146.4z"
        />
        <path
          className="st0"
          fill="#ffffff"
          d="M592.5,207.6L566.7,157c-0.5-0.9-1-1.9-1.6-2.9c-0.6-1-1.3-2-2.2-2.9c-0.9-0.9-1.9-1.7-3-2.2
		c-1.3-0.7-2.8-1.1-4.3-1.2v-1.4h40.3v1.4c-4.7,0.5-7.4,1.6-8.1,3.5c-0.7,2.6-0.2,5.3,1.2,7.6l22.4,44.3h0.3l18.5-40.9
		c0.8-1.7,1.3-3.6,1.5-5.5c0.1-1.6-0.5-3.1-1.5-4.2c-1.4-1.4-3.2-2.4-5.1-2.9c-3-0.9-6.2-1.5-9.4-1.8v-1.4h31.3v1.4
		c-3.2,0.4-6.3,1.8-8.7,3.9c-2,2-3.6,4.4-4.8,7l-20.5,45.7v33c0,0.9,0.1,1.8,0.3,2.7c0.2,0.7,0.6,1.3,1.2,1.6
		c0.9,0.4,1.9,0.7,2.9,0.8c1.3,0.2,3,0.4,5.1,0.6v1.4H583v-1.4c2.1-0.2,3.8-0.4,5.1-0.6c1-0.1,2-0.4,2.9-0.8
		c0.6-0.3,1.1-0.9,1.2-1.6c0.2-0.9,0.3-1.8,0.3-2.7L592.5,207.6z"
        />
        <path
          className="st0"
          fill="#ffffff"
          d="M106.5,270c-4.4-0.1-8.7,1.2-12.3,3.8c-3.7,2.7-6.7,6.3-8.9,10.4c-2.6,4.9-4.4,10.1-5.5,15.6
		c-2.5,12.8-2.5,25.9,0,38.7c1.1,5.4,2.9,10.7,5.5,15.6c2.1,4.1,5.2,7.6,8.9,10.4c7.4,5,17.1,5,24.5,0c3.7-2.7,6.8-6.3,8.9-10.4
		c2.6-4.9,4.4-10.1,5.5-15.6c2.5-12.8,2.5-25.9,0-38.7c-1.1-5.4-2.9-10.7-5.5-15.6c-2.1-4.1-5.2-7.7-8.9-10.4
		C115.2,271.3,110.9,269.9,106.5,270L106.5,270z M106.5,369.5c-6.6,0-13.1-1.2-19.2-3.7c-5.9-2.4-11.3-5.9-15.9-10.4
		c-4.6-4.6-8.3-10-10.8-16c-5.4-13-5.4-27.7,0-40.7c2.6-6,6.2-11.4,10.8-16c4.5-4.5,9.9-8.1,15.9-10.4c12.3-5,26-5,38.3,0
		c5.9,2.4,11.3,5.9,15.9,10.4c4.6,4.6,8.3,10,10.8,16c5.4,13,5.4,27.6,0,40.7c-2.6,6-6.2,11.4-10.8,16c-4.5,4.5-9.9,8.1-15.9,10.4
		C119.6,368.3,113.1,369.6,106.5,369.5"
        />
        <path
          className="st0"
          fill="#ffffff"
          d="M175.7,277.1c0-0.9-0.1-1.8-0.3-2.7c-0.2-0.7-0.6-1.3-1.2-1.6c-0.9-0.4-1.9-0.7-2.9-0.8
		c-1.3-0.2-3-0.4-5.1-0.6V270h72.5l0.7,23.7h-1.4c-0.7-2.9-1.7-5.8-2.8-8.6c-1-2.6-2.5-5-4.3-7.1c-1.8-2-3.9-3.6-6.3-4.8
		c-2.6-1.3-5.5-1.9-8.4-1.8H196V320h12.9c3.8,0.1,7.4-1.4,10-4.1c3-3.5,4.7-7.9,5-12.5h1.4v34.8h-1.4c-0.2-4.6-2-9-5-12.5
		c-2.6-2.7-6.2-4.2-10-4.1H196V361c0,0.9,0.1,1.8,0.3,2.7c0.2,0.7,0.6,1.3,1.2,1.6c0.9,0.4,1.9,0.7,2.9,0.8c1.3,0.2,3,0.4,5.1,0.6
		v1.4h-39.3v-1.4c2.1-0.2,3.8-0.4,5.1-0.6c1-0.1,2-0.4,2.9-0.8c0.6-0.3,1.1-0.9,1.2-1.6c0.2-0.9,0.3-1.8,0.3-2.7L175.7,277.1z"
        />
      </g>
      <rect
        x="55.2"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="88.2"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="121.2"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="154.3"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="187.3"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="253.3"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="286.3"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="319.3"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="352.3"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="385.4"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="418.4"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="517.4"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="550.4"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="583.4"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="649.5"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="682.5"
        y="65.2"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="55.2"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="121.2"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="187.3"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="253.3"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="286.3"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="319.3"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <polyline
        className="st0"
        fill="#ffffff"
        points="352.3,695.4 378.7,695.4 378.7,668.9 352.3,668.9 "
      />
      <rect
        x="418.4"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="451.4"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="484.4"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="517.4"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="583.4"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="616.4"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
      <rect
        x="682.5"
        y="668.9"
        className="st0"
        fill="#ffffff"
        width="26.4"
        height="26.5"
      />
    </svg>
  );
};

export default MainSiteLogoSvg;
