const faceCameraVertexShader = `
  void main() {
    mat4 myModelView = modelViewMatrix;
    myModelView[0][0] = 1.0;
    myModelView[0][1] = 0.0;
    myModelView[0][2] = 0.0;
    myModelView[1][0] = 0.0;
    myModelView[1][1] = 1.0;
    myModelView[1][2] = 0.0;
    myModelView[2][0] = 0.0;
    myModelView[2][1] = 0.0;
    myModelView[2][2] = 1.0;
    vec4 modelViewPosition = myModelView * vec4(position, 1.0);
    gl_Position = projectionMatrix * modelViewPosition;
  }
`;

export default faceCameraVertexShader;
