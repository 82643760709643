import { useEffect } from 'react';

import { useTransition } from '@react-spring/three';

import useGlobalState from '../../../store/globalState';
import EventHoverActive from './EventHoverActive';

const EventHoverActiveWrap = () => {
  const pinsActives = useGlobalState((state) => state.pinsActives);
  const addPinActive = useGlobalState((state) => state.addPinActive);
  const clearPinsActives = useGlobalState((state) => state.clearPinsActives);
  const popups = useGlobalState((state) => state.popups);

  const textures = useGlobalState((state) => state.textures);

  const { circleTexture, squareTexture } = textures;

  useEffect(() => {
    if (popups.length) {
      addPinActive(popups[0].eventID);
    } else {
      clearPinsActives();
    }
  }, [popups, addPinActive, clearPinsActives]);

  const pinHoverTransitions = useTransition(pinsActives, {
    from: { opacity: 0, scale: 0.7 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0.7 },
    keys: (item) => item.eventID,
  });

  return (
    <>
      {pinHoverTransitions((styles, item) =>
        item ? (
          <EventHoverActive
            pinData={item}
            pinHoverAnim={styles}
            circleTexture={circleTexture}
            squareTexture={squareTexture}
          />
        ) : null
      )}
    </>
  );
};

export default EventHoverActiveWrap;
