const createPinHoverOrActiveState = (set) => ({
  pinsActives: [],
  addPinActive: (eventID) =>
    set((state) => {
      // if already in array
      let modifiedData;

      let itemInArr = state.pinsActives.findIndex(
        (item) => item.eventID === eventID
      );
      // XXX: Need this check for when opening a popup
      // otherwise it bugs
      if (itemInArr !== -1) {
        modifiedData = [...state.pinsActives];
      } else {
        // if not in array, push new item
        const [year] = eventID.split('_');
        const [, foundYearVal] = Object.entries(state.allEvents).find(
          ([key]) => key === year
        );
        const foundEventObj = foundYearVal.events.find(
          (evt) => evt.combined_event_id === eventID
        );

        const newPinData = {
          type: foundEventObj.type,
          eventID,
          yStartPos: foundEventObj.yStartPos,
          position: foundEventObj.eventPos,
          headPosition: foundEventObj.headPos,
          height: foundEventObj.height,
        };
        modifiedData = [...state.pinsActives, { ...newPinData }];
      }

      sessionStorage.setItem('hovers', JSON.stringify(modifiedData));
      return {
        pinsActives: modifiedData,
      };
    }),

  clearPinsActives: () =>
    set((state) => {
      let modifiedData;
      // If popup is open, don't do anything,
      if (state.popups.length && state.pinsActives.length) {
        const popupEventID = state.popups[0].eventID;
        const foundMatchingHover = state.pinsActives.find(
          (p) => p.eventID === popupEventID
        );

        if (foundMatchingHover) {
          modifiedData = [{ ...foundMatchingHover }];
          // return { pinsActives: [{ ...foundMatchingHover }] };
        } else {
          modifiedData = [...state.pinsActives];
          // return { pinsActives: [...state.pinsActives] };
        }
      } else {
        // otherwise, close the hover effect
        modifiedData = [];
        // return { pinsActives: [] };
      }

      sessionStorage.setItem('hovers', JSON.stringify(modifiedData));
      return { pinsActives: modifiedData };
    }),
});

export { createPinHoverOrActiveState };
