import { memo } from 'react';
import { useTransition } from '@react-spring/web';

import useGlobalState from '../../../store/globalState.js';
import MachineToggle from './MachineToggle.jsx';

const MachineToggleWrap = () => {
  const machineOpen = useGlobalState((state) => state.machineOpen);

  const machineButtonTransition = useTransition(machineOpen, {
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
    delay: machineOpen ? 100 : 270,
    config: {
      tension: 330,
    },
  });
  const machineToggleTransition = useTransition(machineOpen, {
    from: { rotateZ: -2 },
    enter: { rotateZ: 0 },
    leave: { rotateZ: -2 },
    delay: machineOpen ? 0 : 330,
    config: {
      tension: 280,
    },
  });

  return (
    <group position-y="0.8">
      {machineButtonTransition(
        (buttonStyles, buttonItem) =>
          !buttonItem &&
          machineToggleTransition(
            (iconStyles, iconItem) =>
              !iconItem && (
                <MachineToggle
                  buttonStyles={buttonStyles}
                  iconStyles={iconStyles}
                />
              )
          )
      )}
    </group>
  );
};

export default memo(MachineToggleWrap);
