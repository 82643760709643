import { LANE_HEIGHT } from '../../../config/GLOBAL_CONFIG';

import { MeshNormalMaterial, PlaneBufferGeometry } from 'three';

const laneMaterial = new MeshNormalMaterial({});
laneMaterial.transparent = true;

laneMaterial.onBeforeCompile = function (shader) {
  shader.fragmentShader = shader.fragmentShader.replace(
    'gl_FragColor = vec4( packNormalToRGB( normal ), opacity );',
    [
      'gl_FragColor = vec4( vec3(0.337,0.012,0.196), opacity );',
      'gl_FragColor.a *= pow(1. - pow( gl_FragCoord.z, 100.0 ), 2.);',
    ].join('\n')
  );
};

const planeG = new PlaneBufferGeometry(1, 22);

const Plane = (props) => {
  const laneHeightMult = LANE_HEIGHT * 3;

  return (
    <group>
      <mesh
        {...props}
        material={laneMaterial}
        position={[
          props.posX,
          props.posY ? props.posY : laneHeightMult * 0.5,
          0,
        ]}
        scale={[props.width, 1, 1]}
        geometry={planeG}
      />
    </group>
  );
};

export default Plane;
