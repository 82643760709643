import { useSpring } from '@react-spring/three';
import { memo } from 'react';
import {
  CircleBufferGeometry,
  PlaneBufferGeometry,
  ShaderMaterial,
} from 'three';
import faceCameraVertexShader from '../../../shaders/faceCameraVertexShader';
import { arrowFragmentShader } from './arrowFragmentShader';

const arrowLineGeometry = new PlaneBufferGeometry(0.025, 0.59);
const arrowBtnGeometry = new PlaneBufferGeometry(0.84, 0.65);
const triangleGeometry = new CircleBufferGeometry(0.25, 3);

const arrowBtnMaterial = new ShaderMaterial({
  transparent: true,
  fragmentShader: `
    void main() {
      gl_FragColor = vec4(0., 1., 0., .0);
    }
  `,
});

const arrowLineMaterial = new ShaderMaterial({
  depthWrite: false,
  vertexShader: faceCameraVertexShader,
  fragmentShader: `
    void main() {
      gl_FragColor = vec4(1.,0.89,0.839, 1.);
    }
  `,
});

const Arrow = ({ direction, midLine = true, arrDisabled }) => {
  const arrowTriangleMaterial = arrowFragmentShader.clone();

  useSpring({
    opacity: !arrDisabled ? 1 : 0.4,
    onChange: ({ value: { opacity } }) => {
      arrowTriangleMaterial.uniforms.u_opacity.value = opacity;
    },
  });

  return (
    <group>
      <mesh
        userData="btn"
        geometry={arrowBtnGeometry}
        material={arrowBtnMaterial}
        position-x={0.43 * direction}
        position-z={0.001}
      />
      <group position-y={0.01}>
        <mesh
          userData="line"
          geometry={arrowLineGeometry}
          material={arrowLineMaterial}
          position-x={0.87 * direction}
        />
        {midLine ? (
          <mesh
            userData="midLine"
            geometry={arrowLineGeometry}
            material={arrowLineMaterial}
            position-x={0.025}
          />
        ) : null}
      </group>
      <mesh
        userData="arrow"
        geometry={triangleGeometry}
        material={arrowTriangleMaterial}
        rotation-z={direction !== -1 ? Math.PI * 2 : 1}
        position-x={0.4 * direction}
      />
    </group>
  );
};

export default memo(Arrow);
