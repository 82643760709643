import { LANES_ROT_X } from '../../../config/GLOBAL_CONFIG';
import { useEventLogic } from './useEventLogic';

import { PlaneBufferGeometry, CircleBufferGeometry } from 'three';

// XXX: added here because of performance. Do not move to useEventLogic
const rectG = new PlaneBufferGeometry(0.25, 0.25, 1, 1);
const circleG = new CircleBufferGeometry(0.16, 23);
const lineG = new PlaneBufferGeometry(0.02, 0.25);

const Event = ({ eventData }) => {
  const { eventPos, headPos, bodyHeight } = eventData;

  const pinG = eventData.type === 'circle' ? circleG : rectG;

  const { pinRotation, eventPinMaterial, handleHover, handleSetActiveEvent } =
    useEventLogic(eventData);

  return (
    <group position={eventPos} rotation={[LANES_ROT_X, 0, 0]}>
      <mesh
        userData="class:pinHead"
        rotation={pinRotation}
        position={headPos}
        onPointerOver={() => handleHover(true)}
        onPointerOut={() => handleHover(false)}
        onClick={handleSetActiveEvent}
        material={eventPinMaterial}
        geometry={pinG}
        scale={1}
      ></mesh>

      {bodyHeight !== 0 ? (
        <mesh
          userData="class:pinBody"
          material={eventPinMaterial}
          geometry={lineG}
          position={[0, 0, 0]}
        ></mesh>
      ) : null}
    </group>
  );
};

// XXX: Doesn't need memo
export default Event;
