import { memo } from 'react';

import { a, useSpring } from '@react-spring/web';

import TempIcon from '../../../assets/TempIcon';

const getDelay = (i) => {
  return 500 * i;
};

const CategoryButton = ({
  categoryKey,
  onHandleToggleCategory,
  active,
  icon,
  index,
  children,
}) => {
  const initialSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: getDelay(index),
    config: {
      friction: 200,
      tension: 500,
    },
  });

  return (
    <a.button
      className={`categories-filter__item ${active && 'active'}`}
      data-category-key={categoryKey}
      style={{ ...initialSpring }}
      onClick={() => onHandleToggleCategory(categoryKey)}
    >
      <TempIcon icnName={icon} />
      <div className="categories-filter__item__text">{children}</div>
    </a.button>
  );
};

export default memo(CategoryButton);
