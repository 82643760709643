const TimelineLogoSvg = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 239.48 115.15"
      enableBackground="new 0 0 239.48 115.15"
      xmlSpace="preserve"
    >
      <g transform="translate(-625.746 -1685.713)">
        <g transform="translate(844.143 2929.943)">
          <path
            fill="#FFFFFF"
            d="M-37.99-1175.04c-5.16,0-8.8-4.32-8.8-9.85v-0.11c0-5.37,3.63-9.75,8.8-9.75
                        c3.53,0,5.95,1.69,8.06,4.32l7.69-5.95c-3.27-4.53-8.11-7.69-15.65-7.69c-11.17,0-19.44,8.43-19.44,19.18v-18.44h-10.11v18.15
                        l-14.08-16.75l-1.09-1.4h-0.03l-31.05-36.86l-5.69,0v0h-32.3l0,0h-5.28l-13.94,16.55c-0.93-9.54-8.39-16.15-20.68-16.15h-14.2
                        v36.82h4.53c-5.08,1.77-8.03,5.77-8.03,10.81v0.11c0,7.59,6.06,9.8,13.91,11.59c5,1.16,6.22,2,6.22,3.53v0.1
                        c0,1.58-1.47,2.53-4.21,2.53c-4.27,0-8.32-1.53-11.96-4.42l-5.69,6.8c4.53,4.06,10.75,6.11,17.28,6.11
                        c8.96,0,14.91-4.48,14.91-12.01v-0.1c0-6.9-5.27-9.64-13.75-11.48c-5.11-1.16-6.37-1.9-6.37-3.58v-0.1c0-1.32,1.21-2.37,3.69-2.37
                        c3.29,0.11,6.48,1.09,9.27,2.84l4.84-5.78l0.89-1.06l8.47-10.11l14.15,0.01l-0.11,5.57c-0.53-0.03-1.07-0.06-1.63-0.06
                        c-11.17,0-19.44,8.43-19.44,19.18v0.1c0,11.01,8.48,19.07,19.02,19.07c8.17,0,12.85-3.53,16.33-8.32l-7.69-5.48
                        c-2.21,2.69-4.48,4.48-8.32,4.48c-5.16,0-8.8-4.32-8.8-9.85v-0.11c0-5.37,3.64-9.75,8.8-9.75c3.53,0,5.95,1.69,8.06,4.32
                        l7.69-5.95c-0.96-1.34-2.08-2.55-3.34-3.6l0.07-31.27h6.38v64.8h10.2v-64.8h6.8l0.07,27.92l0,0v36.88h41.53v-20.23l17.3,20.23h7.4
                        v-18.33c0,11.01,8.48,19.07,19.02,19.07c8.17,0,12.85-3.53,16.33-8.32l-7.69-5.48C-31.88-1176.84-34.15-1175.04-37.99-1175.04z
                        M-195.57-1230.75h4.16c6.1,0,10.15,3.42,10.15,9.31v0.1c0,5.95-4.05,9.31-10.15,9.31h-4.16V-1230.75z M-188.18-1203.19l0.05-0.01
                        l-0.01,0.02C-188.15-1203.18-188.16-1203.18-188.18-1203.19L-188.18-1203.19z M-165.42-1217.56l8.52-10.6l-0.34,10.61
                        L-165.42-1217.56z M-105.23-1217.56l-8.18,0l-0.34-10.61L-105.23-1217.56z M-93.73-1175.15h-19.81v-5.85h17.7v-8.06h-17.7v-5.58
                        h19.81L-93.73-1175.15z M-112.87-1203.33l-0.13-6.25l14.15-0.01l5.15,6.26L-112.87-1203.33L-112.87-1203.33z"
          />
          <path
            fill="#FFFFFF"
            d="M-11.86-1175.15v-5.85h17.7v-8.06h-17.7v-5.58H7.68v-8.69h-29.66v36.88H7.94v-8.69
                        L-11.86-1175.15z"
          />
        </g>
        <g transform="translate(508.617 189.064)">
          <g transform="translate(329.129 2901.372)">
            <rect
              x="-208.25"
              y="-1309.68"
              fill="#FFFFFF"
              width="222.2"
              height="4.25"
            />
          </g>
          <g transform="translate(341.644 2882.649)">
            <path
              fill="#FFFFFF"
              d="M-210.52-1297.33h-3.15v-4.25h10.76v4.25h-3.15v21.38h-4.47L-210.52-1297.33z"
            />
            <path
              fill="#FFFFFF"
              d="M-183.5-1301.57h4.47v25.63h-4.47V-1301.57z"
            />
            <path
              fill="#FFFFFF"
              d="M-158.86-1301.57h4.43l3.15,9.37l3.15-9.37h4.43v25.63h-4.28v-15.49l-3.26,9.7h-0.07
                            l-3.26-9.7v15.49h-4.28L-158.86-1301.57z"
            />
            <path
              fill="#FFFFFF"
              d="M-123.6-1301.57h9.81v4.25h-5.35v6.37h4.72v4.25h-4.72v6.52h5.46v4.25h-9.92
                            L-123.6-1301.57z"
            />
            <path
              fill="#FFFFFF"
              d="M-94.05-1301.57h4.47v21.38h4.72v4.25h-9.19V-1301.57z"
            />
            <path
              fill="#FFFFFF"
              d="M-65.53-1301.57h4.47v25.63h-4.47V-1301.57z"
            />
            <path
              fill="#FFFFFF"
              d="M-40.89-1301.57h4.17l4.5,12.96v-12.96h4.36v25.63h-3.95l-4.72-13.58v13.58h-4.36
                            L-40.89-1301.57z"
            />
            <path
              fill="#FFFFFF"
              d="M-7.76-1301.57h9.81v4.25h-5.35v6.37h13.65v4.25H-3.29v6.52h5.46v4.25h-9.92
                            L-7.76-1301.57z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TimelineLogoSvg;
