const createPopupState = (set) => ({
  scheduledActiveEvent: null,
  setScheduledActiveEvent: (scheduledEventID) =>
    set({
      scheduledActiveEvent: scheduledEventID,
    }),

  popups: [],
  togglePopup: (eventID, fromScheduledEvent = false) =>
    set((state) => {
      let modifiedPopupsArr;
      // If already in array, remove it
      const popupInArray = state.popups.find((p) => p.eventID === eventID);
      if (popupInArray) {
        modifiedPopupsArr = [
          ...state.popups.filter((p) => p.eventID !== eventID),
        ];
        return {
          popups: modifiedPopupsArr,
        };
      } else {
        // Not in array -> add it, and set hover state to matching pin
        const [year] = eventID.split('_');
        const [, foundYearVal] = Object.entries(state.allEvents).find(
          ([key]) => key === year
        );
        const foundEventObj = foundYearVal.events.find(
          (evt) => evt.combined_event_id === eventID
        );
        const popupData = {
          eventID,
          pinPopupPos: foundEventObj.popupPos,
        };

        modifiedPopupsArr = [{ ...popupData }];
        if (fromScheduledEvent) {
          return {
            scheduledActiveEvent: null,
            popups: modifiedPopupsArr,
          };
        } else {
          return {
            popups: modifiedPopupsArr,
          };
        }
      }
    }),
  clearPopups: () => set({ popups: [] }),
});

export { createPopupState };
