import { useEffect, useRef } from 'react';

import { Html } from '@react-three/drei/web/Html';

import useGlobalState from '../../../store/globalState';
import YearPointer from './YearPointer';
import HtmlGhostArrows from '../HtmlGhostArrows/HtmlGhostArrows';
import Arrows from './Arrows';
import MachineToggleWrap from '../MachineToggle/MachineToggleWrap';

const LeftControlsArrows = () => {
  const activeYear = useGlobalState((state) => state.activeYear);
  const scheduledActiveEvent = useGlobalState(
    (state) => state.scheduledActiveEvent
  );
  // const clearPinsActives = useGlobalState((state) => state.clearPinsActives);
  const togglePopup = useGlobalState((state) => state.togglePopup);
  const leftControlsArrowsPortalRef = useRef(
    document.getElementById('leftControlsArrowsPortal')
  );

  useEffect(() => {
    if (
      scheduledActiveEvent &&
      scheduledActiveEvent.split('_')[0] === activeYear
    ) {
      togglePopup(scheduledActiveEvent, true);
    }
  }, [scheduledActiveEvent, activeYear, togglePopup]);

  return (
    <group>
      <MachineToggleWrap />

      <Html
        as="div"
        className="arrows-wrap"
        zIndexRange={[300, 300]}
        portal={leftControlsArrowsPortalRef}
      >
        <div className="arrows-wrap__inner">
          <HtmlGhostArrows />
        </div>
      </Html>

      <Arrows />
      <YearPointer />
    </group>
  );
};

// XXX: Doesn't need memo
export default LeftControlsArrows;
