import { ShaderMaterial, Vector3, Vector4 } from 'three';
import { yearGroupFragmentShader } from '../shaders/yearGroupFragmentShader';
import labelFragmentShader from '../shaders/labelFragmentShader';
import { eventPinFragmentShader } from '../shaders/eventPinFragmentShader';

const createTestState = (set) => ({
  lanesLoadedCount: 0,
  updateLanesLoadedCount: () =>
    set((state) => {
      const newCount = state.lanesLoadedCount + 1;
      return { lanesLoadedCount: newCount };
    }),

  // testing ShaderMaterial cloning (Works)
  eventPinShaderMaterial: new ShaderMaterial({
    transparent: true,
    uniforms: {
      u_pinOpacity: {
        value: 1,
      },
      u_pinColor: {
        value: new Vector4(1, 1, 1, 1),
      },
    },
    fragmentShader: eventPinFragmentShader,
  }),

  yearGroupShaderMaterial: new ShaderMaterial({
    transparent: true,
    uniforms: {
      u_labelColor: {
        value: new Vector3(0, 0, 0),
      },
      u_opacity: {
        value: 0.7,
      },
    },
    fragmentShader: yearGroupFragmentShader,
  }),

  labelShaderMaterial: new ShaderMaterial({
    depthWrite: false,
    uniforms: {
      u_labelColor: {
        value: new Vector3(0, 0, 0),
      },
    },
    fragmentShader: labelFragmentShader,
  }),

  yearsDropdownOpen: false,
  toggleYearsDropdownOpen: (newDDBool) => set({ yearsDropdownOpen: newDDBool }),

  machineOpen: true,
  machineOpenToggle: (openCloseBool) => set({ machineOpen: openCloseBool }),

  pinHovered: false,
  togglePinHovered: (newPinHoverBool) => set({ pinHovered: newPinHoverBool }),
});

export { createTestState };
