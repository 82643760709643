import { useRef } from 'react';
import { useMeasure, useScroll } from 'react-use';

const BOX_HEIGHT = 70;

const PopupDesc = ({ description }) => {
  const scrollRef = useRef(null);
  const [descRef, { height }] = useMeasure();
  const { y: scrollY } = useScroll(scrollRef);

  const hasTopFade = scrollY > 0;
  const hasBtmFade =
    height > BOX_HEIGHT && height - BOX_HEIGHT !== scrollY + 10;

  return (
    <div
      className={`
            event-popup__body__content__desc-outer
            ${hasTopFade ? 'top-fade' : ''} 
            ${hasBtmFade ? 'btm-fade' : ''}
          `}
    >
      <div className="event-popup__body__content__desc-wrap" ref={scrollRef}>
        <div
          className="event-popup__body__content__desc"
          ref={descRef}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};

export default PopupDesc;
