import { useRef } from 'react';
import { Html } from '@react-three/drei/web/Html';
import { a, useSpring } from '@react-spring/web';

import glareImage from '../../../assets/img/glare.png';

import './Glare.scss';

const Glare = () => {
  const glareSpring = useSpring({
    from: { opacity: 0, scale: 0, y: -100 },
    to: { opacity: 1, scale: 1, y: 0 },
    config: {
      friction: 40,
    },
  });

  const glarePortalRef = useRef(document.getElementById('glarePortal'));

  return (
    <group position={[0, 24, 0.5]}>
      <Html
        as="div"
        transform
        sprite
        portal={glarePortalRef}
        zIndexRange={[0, -1]}
      >
        <div className="glare-wrap">
          <a.img style={{ ...glareSpring }} src={glareImage} alt="glare" />
        </div>
      </Html>
    </group>
  );
};

// XXX: Doesn't need memo
export default Glare;
