import { memo } from 'react';
import { Canvas } from '@react-three/fiber';
import { Stats } from '@react-three/drei/core/Stats';
import { useSearchParam } from 'react-use';
// import GetInfo from '../../../helpers/GetInfo';
import { OrbitControls } from '@react-three/drei/core/OrbitControls';

import LeftControls from '../LeftControls/LeftControls.jsx';
import Lanes from '../Lanes/Lanes';
import Years from '../Years/Years';
import Glare from '../Glare/Glare.jsx';

const Tracks = () => {
  const orbit_param = useSearchParam('orbit');
  const stats_param = useSearchParam('stats');

  return (
    <Canvas
      colorManagement
      className="timeline-canvas"
      camera={{
        fov: 75,
        near: 0.15,
        far: 35,
        position: [0, -5, 2],
      }}
      powerPreference={'high-performance'}
    >
      <group>
        <LeftControls />
        <Glare />
        <Lanes />
        <Years />
      </group>
      {window.location.hostname === 'localhost' || stats_param ? (
        <Stats className="stats" showPanel={0} />
      ) : null}

      {orbit_param && <OrbitControls />}
    </Canvas>
  );
};

export default memo(Tracks);
