const CloseIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 16 16">
      <path
        d="M1.9,0.824L15.176,14.1L14.1,15.176L0.824,1.9L1.9,0.824Z"
        fill="rgb(255,227,214)"
        stroke="rgb(255,227,214)"
        strokeWidth="1px"
      />
      <path
        d="M15.176,1.901L1.901,15.176L0.824,14.1L14.1,0.824L15.176,1.901Z"
        fill="rgb(255,227,214)"
        stroke="rgb(255,227,214)"
        strokeWidth="1px"
      />
    </svg>
  );
};

export default CloseIcon;
