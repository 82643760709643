import { useEffect, useState, memo } from 'react';
import { useTransition, a } from '@react-spring/web';
import LoaderSvg from '../../../assets/svg/LoaderSvg';

const PopupImage = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imgTransition = useTransition(imageLoaded, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
  });

  useEffect(() => {
    let image = new Image();
    image.src = src;
    image.onload = () => {
      // when it finishes loading, update the component state
      setImageLoaded(true);
    };
    return () => {
      image = null;
    };
  }, [src]);

  return (
    <div className="event-popup__img-wrap">
      {imgTransition((styles, item) =>
        !item ? (
          <a.div
            className="event-popup__img-wrap__loader"
            style={{ ...styles }}
          >
            <LoaderSvg />
          </a.div>
        ) : (
          <a.img src={src} alt={alt} style={{ ...styles }} />
        )
      )}
    </div>
  );
};

export default memo(PopupImage);
