import { useRef, useEffect } from 'react';
import {
  Vector3,
  // ShaderMaterial,
  Color,
} from 'three';

// import labelFragmentShader from '../../../shaders/labelFragmentShader';
import { hexToGL } from '../../../helpers/hexToGl';
import useGlobalState from '../../../store/globalState';

const useLabelLogic = (props) => {
  const textRef = useRef();
  const labelGroupRef = useRef();
  const labelShaderMaterial = useGlobalState(
    (state) => state.labelShaderMaterial
  );
  const quaternion = useGlobalState((state) => state.quaternion);

  const colorArr = hexToGL(Color, props.color);

  const labelMaterial = labelShaderMaterial.clone();
  labelMaterial.onBeforeCompile = (shader) => {
    shader.uniforms.u_labelColor.value = new Vector3(
      colorArr[0],
      colorArr[1],
      colorArr[2]
    );
  };

  useEffect(() => {
    textRef.current.geometry.boundingSphere.radius = 2;
  }, []);

  useEffect(() => {
    if (labelGroupRef.current && quaternion !== null) {
      labelGroupRef.current.quaternion.copy(quaternion);
    }
  }, [quaternion]);

  return {
    textRef,
    labelMaterial,
    labelGroupRef,
  };
};

export { useLabelLogic };
