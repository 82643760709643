import { useRef } from 'react';
import { animated } from '@react-spring/three';
import { Html } from '@react-three/drei/web/Html';
import { PlaneBufferGeometry, ShaderMaterial } from 'three';

import { useLeftControlsLogic } from './useLeftControlsLogic';
import TimeMachine from '../TimeMachine/TimeMachine.jsx';
import LeftControlsArrows from '../LeftControlsArrows/LeftControlsArrows';
import useGlobalState from '../../../store/globalState';
import './LeftControls.scss';

const arrowLineGeometry = new PlaneBufferGeometry(0.025, 0.18);
const arrowLineMaterial = new ShaderMaterial({
  depthWrite: false,
  fragmentShader: `
    void main() {
      gl_FragColor = vec4(1.,0.89,0.839, 1.);
    }
  `,
});

const LeftControls = () => {
  const {
    machinePanelTransition,
    lineTransition,
    getMaterial,
    geometry,
    edgesGeometry,
  } = useLeftControlsLogic();

  const machineOpenToggle = useGlobalState((state) => state.machineOpenToggle);

  const timeMachinePortalRef = useRef(
    document.getElementById('timeMachinePortal')
  );

  return (
    <group position={[-5, 12.5, 0]} rotation-y={Math.PI / 1.68}>
      <lineSegments geometry={edgesGeometry} material={getMaterial(0.99)} />
      <mesh material={getMaterial(0.69)} geometry={geometry} />

      <group position={[0, -11.5, 0]} rotation-z={Math.PI / 2}>
        <group position-y={1.9}>
          <Html
            as="div"
            transform
            sprite
            distanceFactor={3}
            zIndexRange={[400, 400]}
            portal={timeMachinePortalRef}
          >
            {machinePanelTransition(
              (styles, item) =>
                item && (
                  <TimeMachine
                    transitionStyles={styles}
                    onMachineToggle={machineOpenToggle}
                  />
                )
            )}
          </Html>
        </group>

        <animated.mesh
          scale-y={lineTransition.scaleY}
          position-y={lineTransition.posY}
          material={arrowLineMaterial}
          geometry={arrowLineGeometry}
          position-x={0.02}
        ></animated.mesh>
        <LeftControlsArrows />
      </group>
    </group>
  );
};

export default LeftControls;
