import { useCallback, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { useGesture } from '@use-gesture/react';
import { useSpring, config } from '@react-spring/core';
import useGlobalState from '../../../store/globalState';

const useLanesLogic = () => {
  const setCanvasFactor = useGlobalState((state) => state.setCanvasFactor);
  const setCameraQuaternion = useGlobalState(
    (state) => state.setCameraQuaternion
  );
  const familiesColors = useGlobalState((state) => state.familiesColors);

  const {
    viewport: { factor },
  } = useThree();

  const {
    camera: { quaternion },
  } = useThree();

  useEffect(() => {
    setCameraQuaternion(quaternion);
  }, [setCameraQuaternion, quaternion]);

  useEffect(() => {
    setCanvasFactor(factor);
  }, [factor, setCanvasFactor]);

  const [{ movement }, api] = useSpring(() => ({
    movement: 0,
    config: config.slow,
  }));

  const runSpring = useCallback(
    (newY, down = false) => {
      api.start(() => {
        return {
          // movement: down ? newY : 0,
          movement: newY,
        };
      });
    },
    [api]
  );

  useGesture(
    {
      onDrag: ({ event, down, offset: [, oy] }) => {
        if ('key' in event) return;
        runSpring(oy, down);
      },
      onWheel: ({ offset: [, oy], wheeling }) => {
        runSpring(oy, wheeling);
      },
    },
    {
      target: document.getElementById('root'),
      eventOptions: { passive: false },
      drag: {
        filterTaps: true,
        transform: ([, y]) => [0, -y],
        bounds: { top: -0.02, bottom: 0.02 },
        rubberband: true,
      },
      wheel: {
        bounds: { top: -0.02, bottom: 0.02 },
        rubberband: true,
      },
    }
  );

  return {
    movement,
    familiesColors,
  };
};

export { useLanesLogic };
