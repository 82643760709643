import React from 'react';

const CursorPinHover = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pin-hover"
    >
      <path
        d="M5.8558 10.229L4.81079 15.4173L0 4.37323L11.0444 9.18402L5.8558 10.229Z"
        fill="white"
      />
      <path
        className="cursor-pin-hover__plus"
        d="M8.97388 4.98625V3.7542H12.7032V0H14.002V3.7542H17.731V4.98625H14.002V8.7489H12.7032V4.98625H8.97388Z"
        fill="white"
      />
    </svg>
  );
};

export default CursorPinHover;
