import React from 'react';

const CursorDefault = () => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83242 5.83257L4.79155 11L0 0L11 4.79173L5.83242 5.83257Z"
        fill="white"
      />
    </svg>
  );
};

export default CursorDefault;
