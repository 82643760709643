import { createPortal } from 'react-dom';
import { a } from '@react-spring/web';

import './LoadingScreen.scss';
import LoaderSvg from '../../../assets/svg/LoaderSvg';

const LoadingScreen = ({ loaderTransition }) => {
  return createPortal(
    <a.div className="loading-screen" style={{ ...loaderTransition }}>
      <div>
        <LoaderSvg />
        <span>Loading ...</span>
      </div>
    </a.div>,
    document.getElementById('loadingPortal')
  );
};

export default LoadingScreen;
