import { memo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { a, useSpring, config } from '@react-spring/web';

import useGlobalState from '../../../store/globalState';
import CategoryButton from './CategoryButton';

import './CategoriesFilter.scss';

const CategoriesFilter = () => {
  const allCategories = useGlobalState((state) => state.allCategories);
  const clearPinsActives = useGlobalState((state) => state.clearPinsActives);
  const toggleActiveCategory = useGlobalState(
    (state) => state.toggleActiveCategory
  );

  const categoriesSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1500,
    config: config.slow,
  });

  const handleToggleCategory = useCallback(
    (categoryKey) => {
      toggleActiveCategory(categoryKey);
      if (!sessionStorage.getItem('arrowsCheckYear')) {
        sessionStorage.setItem('arrowsCheckYear', true);
        clearPinsActives();
      }
    },
    [clearPinsActives, toggleActiveCategory]
  );

  if (allCategories === null) return null;

  return createPortal(
    <div className="categories-filter">
      <a.h4 style={{ ...categoriesSpring }}>Category filter</a.h4>

      {Object.entries(allCategories).map(
        ([key, { codeName, label, icon, active }], index) => (
          <CategoryButton
            key={key}
            categoryKey={key}
            active={active}
            icon={icon}
            codeName={codeName}
            index={index + 1}
            onHandleToggleCategory={handleToggleCategory}
          >
            {label}
          </CategoryButton>
        )
      )}
    </div>,
    document.getElementById('categoriesPortal')
  );
};

export default memo(CategoriesFilter);
