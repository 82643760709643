import { animated } from '@react-spring/three';

import { LANES, LANE_WIDTH } from '../../../config/GLOBAL_CONFIG';
import Lane from '../Lane/Lane.jsx';
import { useLanesLogic } from './useLanesLogic';

const Lanes = () => {
  const { movement, familiesColors } = useLanesLogic();

  return (
    <animated.group position-y={movement}>
      {LANES.map((track, laneIndex) => {
        let color = familiesColors[track.family.code_name];
        return (
          <Lane
            key={track.posX}
            posX={LANE_WIDTH * track.posX}
            border={track.border}
            color={color}
            text={track.family.label}
            laneIndex={laneIndex}
            labelRotation={0}
          />
        );
      })}
    </animated.group>
  );
};

// XXX: don't use memo here, tested already
export default Lanes;
