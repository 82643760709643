import { PlaneBufferGeometry } from 'three';
import { Text } from 'troika-three-text';
import { extend } from '@react-three/fiber';
import { a, useSpring } from '@react-spring/three';

import fonts from '../../../assets/fonts/fonts';
import { useLabelLogic } from './useLabelLogic';
import { GAP, LANE_WIDTH } from '../../../config/GLOBAL_CONFIG';

const labelG = new PlaneBufferGeometry(LANE_WIDTH - GAP, 0.15);

// Register Text as a react-three-fiber element
extend({ Text });

const opts = {
  font: 'GroteskBold',
  fontSize: 0.09,
  color: '#fff',
  maxWidth: 100,
  lineHeight: 1,
  letterSpacing: 0,
  textAlign: 'justify',
  materialType: 'MeshPhongMaterial',
};

const Label = (props) => {
  const { textRef, labelMaterial, labelGroupRef } = useLabelLogic(props);

  const labelSpring = useSpring({
    from: { opacity: 0, scale: 0 },
    to: { opacity: 1, scale: 1 },
    duration: 5000,
  });

  return (
    <group
      position={[
        props.posX,
        props.posY ? props.posY : props.height * 0.5,
        -props.height * 0.5,
      ]}
      ref={labelGroupRef}
    >
      <a.group opacity={labelSpring.opacity} scale={labelSpring.scale}>
        <text
          {...opts}
          ref={textRef}
          text={props.text}
          font={fonts[opts.font]}
          position-y={-0.01}
          anchorX="center"
          anchorY="middle"
        ></text>
      </a.group>

      <mesh material={labelMaterial} geometry={labelG} />
    </group>
  );
};

// XXX: Doesn't need memo
export default Label;
