import { getPinTypeAndRadius } from '../config/GLOBAL_CONFIG';

const createCategoryState = (set) => ({
  allCategories: null,
  setAllCategories: (fetchedCategories) => {
    const formattedCategories = {};
    Object.entries(fetchedCategories).forEach(([key, label]) => {
      formattedCategories[key] = {
        key,
        label,
        icon: getPinTypeAndRadius(key)[0],
        active: true,
      };
      window.sessionStorage.setItem(key, true);
    });
    set({ allCategories: { ...formattedCategories } });
  },
  toggleActiveCategory: (catKey) => {
    set((state) => {
      const modifiedCategories = { ...state.allCategories };
      const activeCategories = Object.values(modifiedCategories).filter(
        (cat) => cat.active
      );
      if (activeCategories.length === 1 && activeCategories[0].key === catKey) {
        return false;
      }
      modifiedCategories[catKey].active = !modifiedCategories[catKey].active;
      window.sessionStorage.setItem(catKey, modifiedCategories[catKey].active);

      return { allCategories: modifiedCategories };
    });
  },
});

export { createCategoryState };
