const yearGroupFragmentShader = `
    uniform vec3 u_labelColor;
    uniform float u_opacity;
    void main() {
      gl_FragColor = vec4(vec3(1), u_opacity );
      float inc = 1.;
      float incOp = .7;
      float opRS = .5;
      float opRE = .5;
      inc *= pow( gl_FragCoord.z, 22. );
      incOp *= pow( gl_FragCoord.z, 11. );
      if( inc > opRS) gl_FragColor.a = .7;
      if( inc < opRE ) gl_FragColor.a *= pow(inc+incOp, 6.);
    }
`;

export { yearGroupFragmentShader };
