import useGlobalState from '../../../store/globalState.js';
import Arrow from './Arrow.jsx';

const Arrows = () => {
  const yearsPositions = useGlobalState((state) => state.yearsPositions);
  const activeYear = useGlobalState((state) => state.activeYear);
  const animationRunning = useGlobalState((state) => state.animationRunning);

  const yearsArr = Object.keys(yearsPositions);
  const oldestYear = yearsArr[0];
  const newestYear = yearsArr[yearsArr.length - 1];

  const prevDisabled = activeYear === newestYear || animationRunning;
  const nextDisabled = activeYear === oldestYear || animationRunning;

  return (
    <>
      <Arrow direction={-1} midLine={false} arrDisabled={prevDisabled} />
      <Arrow direction={1} arrDisabled={nextDisabled} />
    </>
  );
};

export default Arrows;
