import { ShaderMaterial } from 'three';

const arrowFragmentShader = new ShaderMaterial({
  transparent: true,
  depthWrite: false,
  depthTest: false,
  uniforms: {
    u_opacity: {
      value: 1.0,
    },
  },
  fragmentShader: `
    uniform float u_opacity;
    void main() {
      gl_FragColor = vec4(1.,0.89,0.839, u_opacity);
    }
  `,
});

export { arrowFragmentShader };
