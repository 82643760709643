function hexToGL(Color, hexStr) {
  //check if valid hex value
  if (/^#([0-9A-F]{3}){1,2}$/i.test(hexStr)) {
    let col = new Color(hexStr);
    let out = col.toArray().map((x) => {
      //to fixed 3
      let conv = Math.round(x * 1000) / 1000;
      //append missing periods
      if (conv.toString().indexOf('.') === -1) conv += '.';
      return conv;
    });
    return out;
  } else {
    return '';
  }
}

export { hexToGL };
