import { useState, useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';

import {
  DoubleSide,
  Vector3,
  Raycaster,
  PlaneGeometry,
  MeshStandardMaterial,
} from 'three';

import useYScroll from '../../../hooks/useYScroll';
import useGlobalState from '../../../store/globalState';

const showMarkers = false;
const yearLenMaterial = new MeshStandardMaterial({
  side: DoubleSide,
});
yearLenMaterial.transparent = true;

const dir = new Vector3(0, 0, 1);
dir.normalize();
const origin = new Vector3(3, -1.2, -0.5);
const length = 6;
const raycaster = new Raycaster(origin, dir, 0, 2 * length);

const useYearsLogic = (years) => {
  const [y] = useYScroll();
  let lastYear = years[Object.keys(years)[0]];
  const setActiveYear = useGlobalState((state) => state.setActiveYear);

  const [offsetsPlaced, setOffsetsPlaced] = useState(false);
  const allYearsArrRef = useRef([]);
  const allTargetsRef = useRef([]);
  yearLenMaterial.opacity = showMarkers ? 1 : 0;

  const listenForIntersects = () => {
    const intersects = raycaster.intersectObjects(allTargetsRef.current, false);
    if (intersects.length) {
      for (let i = 0; i < intersects.length; i++) {
        const userData = intersects[i].object.userData;
        if (userData !== lastYear) {
          setActiveYear(userData);
          sessionStorage.setItem('activeYear', userData);
          lastYear = userData;
        }
      }
    }
  };

  useEffect(() => {
    const setGeoH = (object, key) => {
      if (object) {
        const year = years[Object.keys(years)[key]];
        if (year) {
          const length = year.length;
          const width = 0.1;
          const start = year.yStart;
          object.position.set(3, start + length * 0.5, 0);
          const yearLenGeometry = new PlaneGeometry(width, length);
          object.geometry = yearLenGeometry;
        }
      }
    };

    allYearsArrRef.current.forEach((ref, key) => {
      setGeoH(ref.children[0], key);
      if (ref.children[0] && !allTargetsRef.current.includes(ref.children[0])) {
        allTargetsRef.current.push(ref.children[0]);
      }
    });
    setOffsetsPlaced(true);
  }, [years]);

  useFrame(() => {
    if (offsetsPlaced) {
      listenForIntersects();
    }
  });

  const addToRefs = (el) => {
    if (el && !allYearsArrRef.current.includes(el)) {
      allYearsArrRef.current.push(el);
    }
  };

  return {
    addToRefs,
    yearLenMaterial,
    y,
  };
};

export { useYearsLogic };
