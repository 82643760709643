import { a, useSpring } from '@react-spring/web';

import TimelineLogo from '../../HTML/TimelineLogo/TimelineLogo';
import CategoriesFilter from '../../HTML/CategoriesFilter/CategoriesFilter.jsx';
import TracksWrap from '../TracksWrap/TracksWrap.jsx';

import './Timeline.css';
import { memo } from 'react';

const Timeline = () => {
  const appSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <a.div className="timeline-wrap" style={{ ...appSpring }}>
      <TimelineLogo />
      <CategoriesFilter />
      <TracksWrap />
    </a.div>
  );
};

export default memo(Timeline);
