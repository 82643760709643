import { useState } from 'react';

import DeLoreanIcon from '../../../assets/svg/DeLoreanIcon';
import TimeMachineLogo from '../../../assets/svg/TimeMachineLogo';
import useGlobalState from '../../../store/globalState';
import TimeMachineForm from '../../HTML/TimeMachineForm/TimeMachineForm.jsx';
import Panel from '../../UiHelpers/Panel/Panel';
import './TimeMachine.scss';

const yearExists = (years, year) => {
  if (!Object.keys(years).includes(year)) {
    console.error('year doesnt exist: ', year);
    return false;
  }

  return true;
};

const TimeMachine = ({ onMachineToggle, transitionStyles }) => {
  const yearsPositions = useGlobalState((state) => state.yearsPositions);
  const clearPinsActives = useGlobalState((state) => state.clearPinsActives);
  const toggleYearsDropdownOpen = useGlobalState(
    (state) => state.toggleYearsDropdownOpen
  );
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = (manuallyAddedVal = false) => {
    const newVal = manuallyAddedVal ? manuallyAddedVal : inputValue;

    if (!sessionStorage.getItem('arrowsCheckYear')) {
      sessionStorage.setItem('arrowsCheckYear', true);
      clearPinsActives();
    }
    if (yearExists(yearsPositions, newVal)) {
      const newWheelOffset = yearsPositions[newVal].yStart * -1;
      window.runAllSprings(newWheelOffset);
      window.runCircles(newWheelOffset);
    } else {
      toggleYearsDropdownOpen(true);
      return;
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleMachineToggleClose = () => {
    onMachineToggle(false);
  };

  return (
    <Panel
      transitionStyles={transitionStyles}
      onCloseBtnClick={handleMachineToggleClose}
      className="time-machine"
    >
      <TimeMachineLogo />
      <form onSubmit={onFormSubmit}>
        <TimeMachineForm
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleSubmit={handleSubmit}
        />
        <button className="submit" type="submit">
          <DeLoreanIcon />
        </button>
      </form>
    </Panel>
  );
};

export default TimeMachine;
