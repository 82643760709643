import { useEffect, useState } from 'react';

import {
  SPACE_BETWEEN_EVENTS,
  REST_API_BASE,
  LANES,
  OFFSET_START,
  PIN_BODY_HEIGHT,
  getPinTypeAndRadius,
  LANE_WIDTH,
} from '../config/GLOBAL_CONFIG';

import useGlobalState from '../store/globalState';

const removeURLParameterAndGetNewUrl = (parameter) => {
  var urlparts = window.location.search.split('?');
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return (
      window.location.pathname +
      urlparts[0] +
      (pars.length > 0 ? '?' + pars.join('&') : '')
    );
  }
  return window.location.pathname;
};

const removeQueryParam = (queryParam) => {
  const newFormattedURL = removeURLParameterAndGetNewUrl(queryParam);
  window.history.replaceState(null, null, newFormattedURL);
};

const ifParamExistsReturnVal = (paramKey) => {
  let paramVal = false;
  if (window.location.search.length) {
    const paramsArr = window.location.search.split('?')[1];
    const paramsParts = paramsArr.split('&');
    const formattedParamsParts = paramsParts.map((p) => p.split('='));
    const ifEventIDParam = formattedParamsParts.find((p) => p[0] === paramKey);
    if (ifEventIDParam !== undefined) {
      paramVal = ifEventIDParam[1];
    } else {
      paramVal = false;
    }
  }
  return paramVal;
};

// INITIALLY 1
let YEAR_GROUP_Y_START = SPACE_BETWEEN_EVENTS;

const getFormattedEventsAndYears = (eventsOBJ) => {
  const formattedEvents = { ...eventsOBJ };
  const YEARS_POSITIONS = {};
  Object.keys(formattedEvents)
    .reverse()
    .forEach((year) => {
      const EVENTS_NUMBER = formattedEvents[year].events.length;
      formattedEvents[year].eventsNumber = EVENTS_NUMBER;
      // (EVENTS_NUMBER - 1) * SPACE_BETWEEN_EVENTS MEANS IF ONE EVENT
      // IS ONLY PRESENT IT ENDS UP BEING 0, AND WE GET THE CORRECT LENGTH
      const yearLength =
        (EVENTS_NUMBER - 1) * SPACE_BETWEEN_EVENTS + SPACE_BETWEEN_EVENTS * 2;
      formattedEvents[year].length = yearLength;

      YEARS_POSITIONS[year] = {};

      const eventYearStartPos = YEAR_GROUP_Y_START - OFFSET_START;

      formattedEvents[year].yStart = eventYearStartPos;
      YEARS_POSITIONS[year].yStart = YEAR_GROUP_Y_START - SPACE_BETWEEN_EVENTS;

      formattedEvents[year].yEnd = YEAR_GROUP_Y_START + yearLength;
      YEARS_POSITIONS[year].yEnd =
        YEAR_GROUP_Y_START + yearLength - SPACE_BETWEEN_EVENTS;

      const eventsPerPost = {};
      const formattedYearEvents = [];
      formattedEvents[year].events.forEach((e) => {
        const postID = e.ID;
        if (postID in eventsPerPost) {
          eventsPerPost[postID].push({ ...e });
        } else {
          eventsPerPost[postID] = [{ ...e }];
        }
      });
      Object.keys(eventsPerPost).forEach((key) => {
        eventsPerPost[key] = [...eventsPerPost[key].reverse()];
      });
      Object.keys(eventsPerPost).forEach((key) => {
        formattedYearEvents.push(...eventsPerPost[key]);
      });
      formattedEvents[year].events = formattedYearEvents;
      // end:Sort events appropriately

      for (let i = 0; i < EVENTS_NUMBER; i++) {
        const currentEvent = formattedEvents[year].events[i];

        const basePinX = LANES.find(
          (lane) => lane.family.id === formattedEvents[year].events[i].family_id
        ).family.centerPosX;

        // TODO: remove this. It's only a fallback so that we don't waste time and go add categories for each post.
        // Client should add it as it's required for each post that we pull data from
        let fetchedTimelineCategory = currentEvent.timeline_category;
        let tempCatObj = { value: 'cat1', label: 'Objects' };
        let categoryKey = tempCatObj.value;
        if (fetchedTimelineCategory !== null) {
          categoryKey = fetchedTimelineCategory.value;
        }
        const [pinShape, pinRadius] = getPinTypeAndRadius(categoryKey);

        // default for square
        let popupPosOffset = pinRadius;
        if (pinShape === 'circle') popupPosOffset = pinRadius * 2;

        if (pinShape === 'rhombus')
          popupPosOffset = Math.sqrt(
            Math.pow(pinRadius, 2) + Math.pow(pinRadius, 2)
          );

        const popupPosX = +(LANE_WIDTH + basePinX - 1).toFixed(1);

        const popupPosY =
          SPACE_BETWEEN_EVENTS +
          i * SPACE_BETWEEN_EVENTS +
          (YEAR_GROUP_Y_START - OFFSET_START) +
          popupPosOffset / 1.8;

        const popupPosZ = PIN_BODY_HEIGHT + popupPosOffset;
        const headPos = [
          0,
          pinShape === 'rhombus'
            ? pinRadius * 0.67 + PIN_BODY_HEIGHT * 0.5
            : pinShape === 'square'
            ? pinRadius * 0.5 + PIN_BODY_HEIGHT * 0.5
            : pinRadius + PIN_BODY_HEIGHT * 0.5,
          0,
        ];
        const eventPosData = {
          height: pinRadius,
          bodyHeight: PIN_BODY_HEIGHT,
          type: pinShape,
          eventPos: [
            +(LANE_WIDTH + basePinX - 1).toFixed(1),
            SPACE_BETWEEN_EVENTS + i * SPACE_BETWEEN_EVENTS,
            PIN_BODY_HEIGHT * 0.5,
          ],
          headPos,
          yStartPos: eventYearStartPos,
          popupPos: [popupPosX, popupPosY, popupPosZ],
          // Used for moving between event pins on arrows <- and ->
          trackPosY:
            (YEAR_GROUP_Y_START -
              SPACE_BETWEEN_EVENTS +
              (SPACE_BETWEEN_EVENTS + i * SPACE_BETWEEN_EVENTS)) *
            -1,
        };

        formattedEvents[year].events[i] = {
          ...formattedEvents[year].events[i],
          combined_event_id: `${year}_${formattedEvents[year].events[i].ID}_${formattedEvents[year].events[i].event.index}`,
          timeline_category: {
            ...(formattedEvents[year].events[i].timeline_category || {
              ...tempCatObj,
            }),
          },
          ...eventPosData,
        };
      }

      YEAR_GROUP_Y_START += yearLength;
    });

  return {
    formattedEvents,
    YEARS_POSITIONS,
  };
};

const useFetchAndSetData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const setAllEvents = useGlobalState((state) => state.setAllEvents);
  const setYearsPositions = useGlobalState((state) => state.setYearsPositions);
  const setAllCategories = useGlobalState((state) => state.setAllCategories);
  const setUpperBound = useGlobalState((state) => state.setUpperBound);
  const setAnimationReady = useGlobalState((state) => state.setAnimationReady);
  const setDefaultYear = useGlobalState((state) => state.setDefaultYear);
  const setFamiliesColors = useGlobalState((state) => state.setFamiliesColors);
  const setScheduledActiveEvent = useGlobalState(
    (state) => state.setScheduledActiveEvent
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${REST_API_BASE}/timeline-posts`, {
          cache: 'no-cache',
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: '0',
          },
        });
        if (!response.ok) {
          throw new Error('Something went wrong. Try refreshing');
        }
        const data = await response.json();

        const fetchedPosts = data.posts;

        // FALLBACK NEWEST YEAR IF DEFAULT YEAR NOT SET IN ADMIN
        let initialYear = Object.keys(data.posts)[
          Object.keys(data.posts).length - 1
        ];

        // IF DEFAULT YEAR SET IN ADMIN AND IT EXISTS IN THE POSTS OBJECT AS A KEY
        const defYear = data?.timeline_initial_year;
        if (defYear && Object.keys(fetchedPosts).includes(defYear)) {
          initialYear = defYear;
        }

        // IF LINKED TO YEAR WITH YEAR PARAM ?year=2020
        let year_param_val = ifParamExistsReturnVal('year');
        if (year_param_val) {
          if (Object.keys(fetchedPosts).includes(year_param_val)) {
            initialYear = year_param_val;
          }
          removeQueryParam('year');
        }

        let event_id_param_val = ifParamExistsReturnVal('event_id');

        if (event_id_param_val) {
          if (
            event_id_param_val.includes('_') &&
            Object.keys(fetchedPosts).includes(event_id_param_val.split('_')[0])
          ) {
            const splittedParam = event_id_param_val.split('_');

            if (
              splittedParam.length === 3 &&
              fetchedPosts[splittedParam[0]].events.find(
                (post) =>
                  post?.ID === +splittedParam[1] &&
                  post?.event?.index === +splittedParam[2]
              )
            ) {
              initialYear = splittedParam[0];
              setScheduledActiveEvent(event_id_param_val);
            }
          }
          removeQueryParam('event_id');
        }

        setDefaultYear(initialYear);

        setIsLoading(false);
        setAnimationReady(true);

        const { formattedEvents, YEARS_POSITIONS } =
          getFormattedEventsAndYears(fetchedPosts);

        setAllEvents(formattedEvents);
        setYearsPositions(YEARS_POSITIONS);
        setFamiliesColors(data.all_timeline_families_colors);
        const yearsValues = [...Object.values(YEARS_POSITIONS)];
        const farthestBound = Math.max.apply(
          Math,
          yearsValues.map(function (o) {
            return o.yEnd;
          })
        );
        setUpperBound(farthestBound);

        setAllCategories(data.all_timeline_categories[0]);
      } catch (resError) {
        console.error('error: ', resError);
        setIsLoading(false);
        setError(resError);
      }
    })();
  }, [
    setAllCategories,
    setAllEvents,
    setAnimationReady,
    setDefaultYear,
    setFamiliesColors,
    setScheduledActiveEvent,
    setUpperBound,
    setYearsPositions,
  ]);

  const appReady = !isLoading && !error;

  return {
    isLoading,
    error,
    appReady,
  };
};

export default useFetchAndSetData;
