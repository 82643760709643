import { createPortal } from 'react-dom';
import { a, useSpring } from '@react-spring/web';

import useGlobalState from '../../../store/globalState';
import { memo } from 'react';
import TimelineLogoSvg from '../../../assets/svg/TimelineLogoSvg';

const TimelineLogo = () => {
  const defaultYear = useGlobalState((state) => state.defaultYear);

  const initialSpring = useSpring({
    from: { opacity: 0, scale: 0, x: '-50%', y: -100 },
    to: { opacity: 1, scale: 1, x: '-50%', y: 0 },
    config: {
      friction: 200,
      tension: 500,
    },
  });

  const handleGoToDefaultYear = () => {
    window.changeYear(defaultYear);
  };

  return createPortal(
    <a.div
      className="timeline-logo"
      style={{ ...initialSpring }}
      onClick={handleGoToDefaultYear}
    >
      <TimelineLogoSvg />
    </a.div>,
    document.getElementById('logoPortal')
  );
};

export default memo(TimelineLogo);
