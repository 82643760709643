import { useEffect } from 'react';

import useGlobalState from '../../../store/globalState';

const usePopupLogic = () => {
  const allEvents = useGlobalState((state) => state.allEvents);
  const familiesColors = useGlobalState((state) => state.familiesColors);

  const clearPopups = useGlobalState((state) => state.clearPopups);

  useEffect(() => {
    const handleOutsidePopupClose = (e) => {
      if (
        !e.target.closest('.event-popup') &&
        !sessionStorage.getItem('pinHovered')
      ) {
        clearPopups();
      }
    };

    const handleClosePopupOnEsc = ({ keyCode }) => {
      if (keyCode !== 27) return;
      clearPopups();
    };

    window.addEventListener('click', handleOutsidePopupClose, false);
    window.addEventListener('keydown', handleClosePopupOnEsc, false);

    return () => {
      window.removeEventListener('click', handleOutsidePopupClose, false);
      window.removeEventListener('keydown', handleClosePopupOnEsc, false);
    };
  }, [clearPopups]);

  return {
    allEvents,
    handleClosePopup: clearPopups,
    familiesColors,
  };
};

export { usePopupLogic };
