import create from 'zustand';

import { createAnimationState } from './createAnimationState';
import { createEventsState } from './createEventsState';
import { createCategoryState } from './createCategoryState';
import { createYearsState } from './createYearsState';
import { createCanvasFactorState } from './createCanvasFactorState';
import { createUpperBoundState } from './createUpperBoundState';
import { createDefaultYearState } from './createDefaultYearState';
import { createFamiliesColorsState } from './createFamiliesColorsState';
import { createTexturesState } from './createTexturesState';
import { createTestState } from './createTestState';

import { createPopupState } from './createPopupState';
import { createPinHoverOrActiveState } from './createPinHoverOrActiveState';
import { createCameraQuaternion } from './createCameraQuaternion';

const store = (set) => ({
  ...createDefaultYearState(set),
  ...createTexturesState(set),
  ...createAnimationState(set),
  ...createEventsState(set),
  ...createFamiliesColorsState(set),
  ...createTestState(set),
  ...createCategoryState(set),
  ...createYearsState(set),
  ...createCanvasFactorState(set),
  ...createUpperBoundState(set),
  ...createPinHoverOrActiveState(set),
  ...createPopupState(set),
  ...createCameraQuaternion(set),
});

const useGlobalState = create(store);

export default useGlobalState;
