import { ShaderMaterial, CircleBufferGeometry } from 'three';

import { memo } from 'react';
import { a } from '@react-spring/three';

import './MachineToggle.css';
import useGlobalState from '../../../store/globalState';

const machineToggleG = new CircleBufferGeometry(0.3, 17);

const MachineToggle = ({ buttonStyles, iconStyles }) => {
  const machineOpenToggle = useGlobalState((state) => state.machineOpenToggle);
  const textures = useGlobalState((state) => state.textures);

  const { toggleTexture } = textures;

  toggleTexture.anisotropy = 2;

  const machineToggleMat = new ShaderMaterial({
    transparent: false,
    depthWrite: true,
    alphaTest: 0.1,
    uniforms: {
      u_texture: {
        type: 't',
        value: toggleTexture,
      },
    },
    vertexShader: `
      varying vec2 vUv;
      void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vUv = uv;
      }
    `,
    fragmentShader: `
      uniform sampler2D u_texture;
      varying vec2 vUv;

      void main() {
        gl_FragColor = texture2D(u_texture, vUv);
      }
    `,
  });

  return (
    <a.group opacity={buttonStyles.opacity} scale={buttonStyles.scale}>
      <a.mesh
        rotation-z={iconStyles.rotateZ}
        geometry={machineToggleG}
        material={machineToggleMat}
        onClick={() => machineOpenToggle(true)}
      />
    </a.group>
  );
};

export default memo(MachineToggle);
