const createYearsState = (set) => ({
  yearsPositions: null,
  setYearsPositions: (calculatedYears) =>
    set({ yearsPositions: { ...calculatedYears } }),

  activeYear: null,
  setActiveYear: (newActiveYear) => set({ activeYear: newActiveYear }),
});

export { createYearsState };
