import { useRef } from 'react';
import { Html } from '@react-three/drei/web/Html';

import { useTransition } from '@react-spring/three';
import Popup from './Popup.jsx';
import useGlobalState from '../../../store/globalState';

const PopupWrap = () => {
  const popups = useGlobalState((state) => state.popups);
  const popupPortalRef = useRef(document.getElementById('popupPortal'));
  const popupsTransitions = useTransition(popups, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    keys: (item) => item.eventID,
  });

  return (
    <>
      {popupsTransitions((styles, item) =>
        item ? (
          <Html
            as="div"
            position={item.pinPopupPos}
            transform
            sprite
            portal={popupPortalRef}
            className="popupPortal__child"
            distanceFactor={3.5}
            zIndexRange={[500, 500]}
          >
            <Popup activeEvent={item} transitionStyles={styles} />
          </Html>
        ) : null
      )}
    </>
  );
};

export default PopupWrap;
