import { a, useSpring } from '@react-spring/three';
import { extend } from '@react-three/fiber';
import { Text } from 'troika-three-text';

import fonts from '../../../assets/fonts/fonts';
// import { yearGroupFragmentShader } from '../../../shaders/yearGroupFragmentShader';

import {
  LANES_ROT_X,
  YEAR_LABEL_PLANE_HEIGHT,
  YEAR_LABEL_PLANE_WIDTH,
} from '../../../config/GLOBAL_CONFIG';

import Event from '../Event/Event.jsx';
import {
  // MeshNormalMaterial,
  PlaneGeometry,
  EdgesGeometry,
  // ShaderMaterial,
  // Vector3,
} from 'three';
import { memo } from 'react';
import useGlobalState from '../../../store/globalState';

const opts = {
  font: 'GroteskBold',
  fontSize: 0.13,
  color: '#99ccff',
  maxWidth: 100,
  lineHeight: 1,
  letterSpacing: 0,
  textAlign: 'justify',
  materialType: 'MeshPhongMaterial',
};

const stripGeometry = new PlaneGeometry(
  YEAR_LABEL_PLANE_WIDTH,
  YEAR_LABEL_PLANE_HEIGHT
);

const stripEdges = new EdgesGeometry(stripGeometry);

extend({ Text });

const YearGroup = ({
  yearName,
  yStartPos,
  yearEvents,
  yearLength,
  isLast = false,
}) => {
  const yearGroupSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const yearGroupShaderMaterial = useGlobalState(
    (state) => state.yearGroupShaderMaterial
  );

  const stripMaterial = yearGroupShaderMaterial.clone();

  return (
    <a.group position={[0, yStartPos, 0]} opacity={yearGroupSpring.opacity}>
      {yearEvents.map((evt) => (
        <Event
          key={yearName + '_' + evt.ID + '_' + evt.event.index}
          eventData={evt}
        />
      ))}

      <group position={[0, 0, 0.01]}>
        <a.group position-y={yearGroupSpring.positionY}>
          <text
            {...opts}
            text={yearName}
            font={fonts[opts.font]}
            position={[-2.85, -0.13, 0]}
            rotation-x={LANES_ROT_X}
            anchorX="center"
            anchorY="bottom"
            material={stripMaterial}
          ></text>

          <mesh geometry={stripGeometry} material={stripMaterial}></mesh>
          <lineSegments
            geometry={stripEdges}
            material={stripMaterial}
          ></lineSegments>
        </a.group>
      </group>

      {isLast ? (
        <group position={[0, yearLength, 0.01]}>
          <text
            {...opts}
            text="THE END"
            font={fonts[opts.font]}
            position={[-3, 0, 0]}
            rotation-x={LANES_ROT_X}
            anchorX="center"
            anchorY="bottom"
            material={stripMaterial}
          ></text>

          <a.mesh geometry={stripGeometry} material={stripMaterial} />
          <lineSegments
            geometry={stripEdges}
            material={stripMaterial}
          ></lineSegments>
        </group>
      ) : null}
    </a.group>
  );
};

export default memo(YearGroup);
