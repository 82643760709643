import React from 'react';

const TempIcon = ({ icnName }) => {
  return (
    <div className="category-icn--wrap">
      <div className={`category-icn category-icn--${icnName}`} />
    </div>
  );
};

export default TempIcon;
