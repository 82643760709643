const hexToRgb = (hex) => {
  const noHash = hex.split('#')[1];
  var bigint = parseInt(noHash, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return r + ',' + g + ',' + b;
};

export { hexToRgb };
