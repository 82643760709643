import { useRef, useEffect } from 'react';
import { useTransition } from '@react-spring/web';

import useGlobalState from '../../../store/globalState';

const useTimeMachineFormLogic = (inputValue, setInputValue, handleSubmit) => {
  const ddRef = useRef(null);
  const prevActive = useRef('');

  const yearsPositions = useGlobalState((state) => state.yearsPositions);
  const activeYear = useGlobalState((state) => state.activeYear);
  const clearPinsActives = useGlobalState((state) => state.clearPinsActives);
  const yearsDropdownOpen = useGlobalState((state) => state.yearsDropdownOpen);
  const toggleYearsDropdownOpen = useGlobalState(
    (state) => state.toggleYearsDropdownOpen
  );

  const allYears = Object.keys(yearsPositions).reverse();
  const filteredYears = allYears.filter((year) => year.startsWith(inputValue));

  const ifOneIndDdSameAsInput =
    filteredYears.length === 1 && filteredYears[0] === inputValue;

  const dropdownTransition = useTransition(
    yearsDropdownOpen && !ifOneIndDdSameAsInput,
    {
      from: { opacity: 0, translateY: '15px' },
      enter: { opacity: 1, translateY: '0px' },
      leave: { opacity: 0, translateY: '15px' },
      config: {
        tension: 700,
        mass: 0.8,
      },
    }
  );

  const handleChange = (e) => {
    const newVal = e.target.value;
    // Allows only integers to be written
    if (/^-?\d*$/.test(newVal)) {
      setInputValue(newVal);
    }
  };

  const handleCloseDD = (event) => {
    const nextFocusedEL = event.relatedTarget;

    if (
      nextFocusedEL === null ||
      (ddRef.current && !nextFocusedEL.closest('.filter-dropdown'))
    ) {
      toggleYearsDropdownOpen(false);
    }
  };

  const handleBtnClick = (e, newYear) => {
    e.preventDefault();
    handleSubmit(newYear);
    toggleYearsDropdownOpen(false);
  };

  useEffect(() => {
    const onClickOutside = (e) => {
      if (ddRef.current && !e.target.closest('.filter-dropdown')) {
        toggleYearsDropdownOpen(false);
      }
    };
    window.addEventListener('click', onClickOutside);
    return () => {
      window.removeEventListener('click', onClickOutside);
    };
  }, [toggleYearsDropdownOpen]);

  const handleFocusInput = (e) => {
    clearPinsActives();
    prevActive.current = activeYear;
    toggleYearsDropdownOpen(true);
  };

  const handleBlurInput = (e) => {
    e.preventDefault();
    const nextFocusedEL = e.relatedTarget;

    if (
      prevActive.current.length &&
      (nextFocusedEL === null || !nextFocusedEL.closest('.submit')) &&
      (nextFocusedEL === null || !nextFocusedEL.closest('.filter-dropdown'))
    ) {
      setInputValue(prevActive.current);
    }
  };

  // OK ----------------------------
  useEffect(() => {
    if (activeYear) {
      prevActive.current = activeYear;
      setInputValue(activeYear);
    }
  }, [activeYear, setInputValue]);

  return {
    handleChange,
    handleFocusInput,
    handleBlurInput,
    dropdownTransition,
    handleCloseDD,
    handleBtnClick,
    ddRef,
    filteredYears,
  };
};

export { useTimeMachineFormLogic };
