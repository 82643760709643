const eventPinFragmentShader = `
    uniform vec4 u_pinColor;
    uniform float u_pinOpacity;

    void main() {
      // gl_FragColor = u_pinColor;
      gl_FragColor = vec4(vec3(1.), u_pinOpacity);

      if (u_pinOpacity >= 1.) {
        float inc = 1.;
        float incOp = 1.;
        inc *= pow( gl_FragCoord.z, 20.0 );
        incOp *= pow( gl_FragCoord.z, 10.0 );
        if( inc < .5) gl_FragColor.a *= pow(inc+incOp, 10.);
      } else {
        float incA = 1.;
        float incOpA = 1.;
        incA *= pow( gl_FragCoord.z, 20.0 );
        incOpA *= pow( gl_FragCoord.z, 10.0 );
        if( incA < .38) gl_FragColor.a *= pow(incA+incOpA, 10.);
      }

    }
`;

export { eventPinFragmentShader };
// const eventPinFragmentShader = `
//     uniform vec4 u_pinColor;
//     uniform float u_pinOpacity;

//     void main() {
//       // gl_FragColor = u_pinColor;
//       gl_FragColor = vec4(vec3(1.), u_pinOpacity);

//       if (u_pinColor.w >= 1.) {
//         float inc = 1.;
//         float incOp = 1.;
//         inc *= pow( gl_FragCoord.z, 20.0 );
//         incOp *= pow( gl_FragCoord.z, 10.0 );
//         if( inc < .5) gl_FragColor.a *= pow(inc+incOp, 10.);
//       } else {
//         float incA = 1.;
//         float incOpA = 1.;
//         incA *= pow( gl_FragCoord.z, 20.0 );
//         incOpA *= pow( gl_FragCoord.z, 10.0 );
//         if( incA < .38) gl_FragColor.a *= pow(incA+incOpA, 10.);
//       }

//     }
// `;

// export { eventPinFragmentShader };
