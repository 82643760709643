import useGlobalState from '../../../store/globalState';

const YEARS_INC = 10;

const HtmlGhostArrows = () => {
  const yearsPositions = useGlobalState((state) => state.yearsPositions);
  const activeYear = useGlobalState((state) => state.activeYear);
  const animationRunning = useGlobalState((state) => state.animationRunning);

  const clearPinsActives = useGlobalState((state) => state.clearPinsActives);

  const yearsArr = Object.keys(yearsPositions);
  const oldestYear = yearsArr[0];
  const newestYear = yearsArr[yearsArr.length - 1];

  const handleGoToYear = (e, incDec) => {
    e.stopPropagation();

    const newIncDec = incDec * YEARS_INC;

    const actYearIndex = yearsArr.indexOf(activeYear);
    const yearExist = yearsPositions[yearsArr[actYearIndex + newIncDec]];

    const hasNext = actYearIndex !== 0;
    const hasPrev = actYearIndex !== yearsArr.length - 1;

    let newWheelOffset;

    if (incDec === 1 && !yearExist) {
      newWheelOffset =
        yearsPositions[yearsArr[yearsArr.length - 1]].yStart * -1;
    } else if (incDec === -1 && !yearExist) {
      newWheelOffset = yearsPositions[yearsArr[0]].yStart * -1;
    } else {
      newWheelOffset =
        yearsPositions[yearsArr[actYearIndex + newIncDec]].yStart * -1;
    }

    if (!sessionStorage.getItem('arrowsCheckYear')) {
      sessionStorage.setItem('arrowsCheckYear', true);
      clearPinsActives();
    }

    if (incDec === -1) {
      if (!hasNext) return;
      window.runAllSprings(newWheelOffset);
    } else if (incDec === 1) {
      if (!hasPrev) return;
      window.runAllSprings(newWheelOffset);
    }
  };

  const prevDisabled = animationRunning || activeYear === newestYear;
  const nextDisabled = animationRunning || activeYear === oldestYear;

  return (
    <>
      <button
        disabled={nextDisabled}
        className="arrow-btn arrow-btn--next"
        onClick={(e) => handleGoToYear(e, -1)}
      />
      <button
        disabled={prevDisabled}
        className="arrow-btn arrow-btn--prev"
        onClick={(e) => handleGoToYear(e, 1)}
      />
    </>
  );
};

export default HtmlGhostArrows;

//     transform: skew(
// 1deg,
// 338deg) scaleX(0.7) translateX(1
// px
// ) rotate3d(1, 1, 1,
// 359deg);
