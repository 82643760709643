import { memo } from 'react';
import { createPortal } from 'react-dom';

import { a, useSpring } from '@react-spring/web';
import MainSiteLogoSvg from '../../../assets/svg/MainSiteLogoSvg';

import './MainSiteLogo.scss';

const MainSiteLogo = () => {
  const logoTransition = useSpring({
    from: { opacity: 0, x: -20 },
    to: { opacity: 1, x: 0 },
  });

  return createPortal(
    <a.a
      className="main-site-logo"
      href="https://www.historyofdatascience.com/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ ...logoTransition }}
    >
      <MainSiteLogoSvg />
    </a.a>,
    document.getElementById('logoPortal')
  );
};

export default memo(MainSiteLogo);
