import { a } from '@react-spring/web';

import { useTimeMachineFormLogic } from './useTimeMachineFormLogic';

import './TimeMachineForm.scss';

const TimeMachineForm = ({ inputValue, setInputValue, handleSubmit }) => {
  const {
    handleChange,
    handleFocusInput,
    handleBlurInput,
    dropdownTransition,
    handleCloseDD,
    handleBtnClick,
    ddRef,
    filteredYears,
  } = useTimeMachineFormLogic(inputValue, setInputValue, handleSubmit);

  return (
    <div className="filter-dropdown">
      <div className="filter-dropdown__input-wrap">
        <input
          className="filter-dropdown__input"
          maxLength="4"
          value={inputValue}
          onChange={handleChange}
          onFocus={handleFocusInput}
          onBlur={handleBlurInput}
        />
      </div>
      {dropdownTransition(
        (styles, dd) =>
          dd && (
            <a.div
              className="filter-dropdown__results"
              style={styles}
              ref={ddRef}
              onBlur={handleCloseDD}
            >
              {filteredYears.length
                ? filteredYears.map((year) => (
                    <button
                      className="filter-dropdown__result"
                      key={year}
                      type="button"
                      onClick={(e) => handleBtnClick(e, year)}
                    >
                      {year}
                    </button>
                  ))
                : 'Not found'}
            </a.div>
          )
      )}
    </div>
  );
};

export default TimeMachineForm;
