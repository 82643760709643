import { ShaderMaterial, PlaneBufferGeometry } from 'three';
import { a } from '@react-spring/three';

import { LANES_ROT_X } from '../../../config/GLOBAL_CONFIG';
import activeEventVertexShader from '../../../shaders/activeEventVertexShader';
import activeEventFragmentShader from '../../../shaders/activeEventFragmentShader';

const EventHoverActive = ({
  pinData,
  pinHoverAnim,
  circleTexture,
  squareTexture,
}) => {
  const { position, height, headPosition, yStartPos, type } = pinData;

  const hoverMaterial = new ShaderMaterial({
    transparent: true,
    depthWrite: false,
    uniforms: {
      u_texture: {
        type: 't',
        value: type === 'circle' ? circleTexture : squareTexture,
      },
    },
    vertexShader: activeEventVertexShader,
    fragmentShader: activeEventFragmentShader,
  });

  const rotationZ = [0, 0, type === 'rhombus' ? Math.PI / 1.34 : 0];

  const hoverPosition = [...position];
  hoverPosition[1] += yStartPos;

  const backPlanePos = [...headPosition];
  // backPlanePos[2] += -0.01;

  const HoverGeometry =
    type === 'circle'
      ? new PlaneBufferGeometry(height * 2, height * 2, 1, 1)
      : new PlaneBufferGeometry(height, height, 1, 1);

  return (
    <group position={hoverPosition} rotation-x={LANES_ROT_X}>
      <a.group
        position={backPlanePos}
        opacity={pinHoverAnim.opacity}
        scale={pinHoverAnim.scale}
      >
        <mesh
          material={hoverMaterial}
          scale={1.3}
          geometry={HoverGeometry}
          rotation={rotationZ}
        ></mesh>
      </a.group>
    </group>
  );
};

export default EventHoverActive;
