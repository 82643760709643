import { useCallback, useRef, useEffect } from 'react';
import { useSpring } from '@react-spring/core';
import { useGesture } from '@use-gesture/react';
import useGlobalState from '../store/globalState';
import { SPACE_BETWEEN_EVENTS } from '../config/GLOBAL_CONFIG';

const normalClamp = (min, max, val) => {
  return Math.max(Math.min(val, max), min);
};

const ARROWS_UP_DOWN_SPEED = 2;
// XXX: find out why this is 2
const BOTTOM_BOUND = 2;

const useYScroll = () => {
  const factor = useGlobalState((state) => state.canvasFactor);
  const upperBound = useGlobalState((state) => state.upperBound);
  const yearsPositions = useGlobalState((state) => state.yearsPositions);
  const allEvents = useGlobalState((state) => state.allEvents);
  const defaultYear = useGlobalState((state) => state.defaultYear);
  const addPinActive = useGlobalState((state) => state.addPinActive);
  const clearPinsActives = useGlobalState((state) => state.clearPinsActives);
  const togglePopup = useGlobalState((state) => state.togglePopup);
  const setAnimationRunning = useGlobalState(
    (state) => state.setAnimationRunning
  );

  const rootRef = useRef(document.getElementById('root'));

  let farthestBound = 100;
  farthestBound = upperBound;
  const topBound = -farthestBound + SPACE_BETWEEN_EVENTS;

  const runAllSprings = (newVal) => {
    runSpring(newVal);
    window.runCircles(newVal);
  };

  const [{ y }, api] = useSpring(() => ({
    y: 0,
    config: {
      precision: 0.005,
      mass: 1,
      tension: 280,
      friction: 60,
    },
    onStart: {
      y: () => {
        setAnimationRunning(true);
      },
    },
    onRest: {
      y: () => {
        setAnimationRunning(false);
      },
    },
  }));

  const runSpring = useCallback(
    (newY) => {
      api.start(() => {
        sessionStorage.setItem('y', newY);
        return {
          y: newY,
        };
      });
    },
    [api]
  );

  const changeYear = useCallback(
    (newYear) => {
      const newWheelOffset = yearsPositions[newYear].yStart * -1;
      runSpring(newWheelOffset);
      window.runCircles(newWheelOffset);
    },
    [runSpring, yearsPositions]
  );

  window.runAllSprings = runAllSprings;
  window.changeYear = changeYear;

  // If initial year to show set in backend, go to it
  useEffect(() => {
    if (defaultYear) {
      changeYear(defaultYear);
    }
  }, [defaultYear, changeYear]);

  useGesture(
    {
      onDrag: ({ offset: [, oy], cancel }) => {
        if (!sessionStorage.getItem('arrowsCheckYear')) {
          sessionStorage.setItem('arrowsCheckYear', true);
        }
        if (oy > 0.5 || oy < topBound - 0.5) cancel();
        runAllSprings(oy);
      },
      onWheel: ({ offset: [, oy] }) => {
        if (!sessionStorage.getItem('arrowsCheckYear')) {
          sessionStorage.setItem('arrowsCheckYear', true);
        }
        if (oy > 0.5 || oy < topBound - 0.5) return;
        runAllSprings(oy);
      },
    },
    {
      target: rootRef.current,
      eventOptions: { passive: false },
      drag: {
        filterTaps: true,
        transform: ([, y]) => [0, -y / factor],
        from: () => [0, y.get()],
        bounds: {
          top: topBound,
          bottom: 0,
        },
        rubberband: true,
      },
      wheel: {
        transform: ([, y]) => [0, y / factor],
        from: () => [0, y.get()],
        bounds: {
          top: topBound,
          bottom: 0,
        },
        rubberband: true,
      },
    }
  );

  // Arrows logic ----------

  const eventsFlattened = [];
  Object.values(allEvents).forEach((y) => {
    const yEventsReverse = [...y.events].reverse();
    yEventsReverse.forEach((e) => eventsFlattened.push(e));
  });

  sessionStorage.setItem('arrowsCheckYear', true);
  let eventIndex = 0;

  const navigateEvents = (newTrackY, type) => {
    runAllSprings(newTrackY);
    clearPinsActives();
    if (window.sessionStorage.getItem(type) === 'true') {
      addPinActive(eventsFlattened[eventIndex].combined_event_id);
    }
  };

  useEffect(() => {
    const openPopupOnEnter = (e) => {
      if (e.keyCode === 13) {
        const eventIDData = JSON.parse(sessionStorage.getItem('hovers'));
        if (eventIDData.length) {
          const { eventID } = eventIDData[0];
          togglePopup(eventID);
        }
      }
    };
    window.addEventListener('keypress', openPopupOnEnter);

    return () => {
      window.removeEventListener('keypress', openPopupOnEnter);
    };
  }, [togglePopup]);

  // Add enter logic to open a popup if item hovered (got to it with <- -> arrows)

  useGesture(
    {
      onDrag: ({ event, elapsedTime }) => {
        if ('key' in event) {
          // Prevent bugs inside input and dropdown in TimeMachine
          if (
            document.activeElement.classList.contains(
              'filter-dropdown__input'
            ) ||
            document.activeElement.classList.contains('filter-dropdown__result')
          ) {
            return;
          }

          if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            // XXX: We are using sessionStorage here instead of global state activeYear because of performance
            // XXX: Do not use state from the global state

            const activeYear = sessionStorage.getItem('activeYear');

            if (elapsedTime === 0) return;

            if (event.key === 'ArrowLeft') {
              if (sessionStorage.getItem('arrowsCheckYear')) {
                // in year lookup mode
                const startElID =
                  allEvents[activeYear].events[0].combined_event_id;
                const flatIndex = eventsFlattened.findIndex(
                  (e) => e.combined_event_id === startElID
                );
                eventIndex = flatIndex - 1;
                sessionStorage.removeItem('arrowsCheckYear');

                const newTrackY = eventsFlattened[eventIndex].trackPosY;
                const category =
                  eventsFlattened[eventIndex].timeline_category.value;
                navigateEvents(newTrackY, category);
              } else {
                // Not in year lookup mode
                if (eventIndex >= eventsFlattened.length - 1) return;
                eventIndex++;
                const newTrackY = eventsFlattened[eventIndex].trackPosY;
                const category =
                  eventsFlattened[eventIndex].timeline_category.value;
                navigateEvents(newTrackY, category);
              }
            } else {
              if (sessionStorage.getItem('arrowsCheckYear')) {
                // in year lookup mode
                const startElID =
                  allEvents[activeYear].events[
                    allEvents[activeYear].events.length - 1
                  ].combined_event_id;
                const flatIndex = eventsFlattened.findIndex(
                  (e) => e.combined_event_id === startElID
                );
                eventIndex = flatIndex + 1;
                sessionStorage.removeItem('arrowsCheckYear');

                const newTrackY = eventsFlattened[eventIndex].trackPosY;
                const category =
                  eventsFlattened[eventIndex].timeline_category.value;
                navigateEvents(newTrackY, category);
              } else {
                // Not in year lookup mode
                if (eventIndex <= 0) return;
                eventIndex--;
                const newTrackY = eventsFlattened[eventIndex].trackPosY;
                const category =
                  eventsFlattened[eventIndex].timeline_category.value;
                navigateEvents(newTrackY, category);
              }
            }
          } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            if (!sessionStorage.getItem('arrowsCheckYear')) {
              sessionStorage.setItem('arrowsCheckYear', true);
              clearPinsActives();
            }
            const yToClamp = y.get() * -1;
            const TOP_BOUND = (topBound + 1) * -1;
            const newClampedVal =
              normalClamp(BOTTOM_BOUND, TOP_BOUND, yToClamp) * -1;

            if (event.key === 'ArrowUp') {
              runAllSprings(newClampedVal - ARROWS_UP_DOWN_SPEED);
            } else {
              runAllSprings(newClampedVal + ARROWS_UP_DOWN_SPEED);
            }
          }
        }
        return;
      },
    },
    {
      target: window,
      eventOptions: { passive: false },
      drag: {
        filterTaps: true,
        transform: ([, y]) => [0, y / factor],
        from: () => [0, y.get()],
      },
    }
  );

  return [y, runSpring, api];
};

export default useYScroll;
