import { useRef, useEffect, useState, memo } from 'react';
import { createPortal } from 'react-dom';
import { a, useSpring } from '@react-spring/web';

import CursorDefault from './CursorDefault';
import CursorPinHover from './CursorPinHover';

import './Cursor.scss';
import { useCursorMove } from './useCursorMove';
import useGlobalState from '../../../store/globalState';

const Cursor = () => {
  const pointerRef = useRef(null);
  const [mouseLeftDoc, setMouseLeftDoc] = useState(false);

  const { x, y } = useCursorMove();

  const cursorSpringStyles = useSpring({
    opacity: mouseLeftDoc ? 0 : 1,
    scale: mouseLeftDoc ? 0 : 1,
  });

  // const pinHovered = window.sessionStorage.getItem('pinHovered');
  const pinHovered = useGlobalState((state) => state.pinHovered);

  let CursorPointer = CursorDefault;

  if (pinHovered) {
    CursorPointer = CursorPinHover;
  }

  useEffect(() => {
    const onDocMouseLeave = () => {
      setMouseLeftDoc(true);
    };
    const onDocMouseEnter = () => {
      setMouseLeftDoc(false);
    };

    window.document.addEventListener('mouseleave', onDocMouseLeave);
    window.document.addEventListener('mouseenter', onDocMouseEnter);

    return () => {
      window.document.removeEventListener('mouseleave', onDocMouseLeave);
      window.document.removeEventListener('mouseenter', onDocMouseEnter);
    };
  }, []);

  return createPortal(
    <a.div
      className={`custom-cursor`}
      ref={pointerRef}
      style={{
        top: y,
        left: x,
        ...cursorSpringStyles,
      }}
    >
      <CursorPointer />
    </a.div>,
    document.getElementById('pointerPortal')
  );
};

export default memo(Cursor);
