import { useMove } from '@use-gesture/react';
import { useSpring } from '@react-spring/web';
import { useCallback } from 'react';

const useCursorMove = () => {
  const [{ x, y }, api] = useSpring(() => ({
    x: 0,
    y: 0,
    config: {
      tension: 0,
      friction: 0,
    },
  }));

  const runCursorSpring = useCallback(
    ([x, y]) => {
      api.start(() => ({
        x,
        y,
      }));
    },
    [api]
  );

  useMove(
    (e) => {
      runCursorSpring(e.xy);
    },
    {
      target: window,
      eventOptions: { passive: false },
    }
  );

  return {
    x,
    y,
  };
};

export { useCursorMove };
