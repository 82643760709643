import { useTexture } from '@react-three/drei';
import { useTransition } from '@react-spring/web';

import CanvasCircles from './components/KONVA/CanvasCircles/CanvasCircles';
import Timeline from './components/FIBER/Timeline/Timeline.jsx';
import useFetchAndSetData from './hooks/useFetchAndSetData';
import useGlobalState from './store/globalState';
import Cursor from './components/HTML/Cursors/Cursor';
import MainSiteLogo from './components/HTML/MainSiteLogo/MainSiteLogo';
import LoadingScreen from './components/HTML/LoadingScreen/LoadingScreen';

function App() {
  const { isLoading, error, appReady } = useFetchAndSetData();

  const setTextures = useGlobalState((state) => state.setTextures);
  const lanesLoadedCount = useGlobalState((state) => state.lanesLoadedCount);

  const [circleTexture, squareTexture, toggleTexture] = useTexture([
    'circleTexture.png',
    'squareTexture.png',
    'toggleTexture.png',
  ]);

  setTextures({ circleTexture, squareTexture, toggleTexture });

  const loaderTransition = useTransition(lanesLoadedCount !== 5, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  let AppUI = null;

  if (error && !isLoading) {
    AppUI = (
      <div className="app-error">
        <div>
          <h1>
            Something went wrong.
            <br /> Try checking your internet connection or refreshing this
            page.
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className="timeline-app">
      <CanvasCircles />
      <Cursor />
      {appReady && <Timeline />}
      {AppUI}
      <MainSiteLogo />
      {!error &&
        loaderTransition((styles, item) =>
          item ? <LoadingScreen loaderTransition={styles} /> : null
        )}
    </div>
  );
}

// XXX: don't use memo, already tested
export default App;
