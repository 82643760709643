import { a } from '@react-spring/three';
import { useYearsLogic } from './useYearsLogic';

import YearGroup from '../YearGroup/YearGroup.jsx';
import useGlobalState from '../../../store/globalState';
import PopupWrap from '../Popup/PopupWrap';
import EventHoverActiveWrap from '../Event/EventHoverActiveWrap';

const Years = () => {
  const years = useGlobalState((state) => state.allEvents);

  const { addToRefs, yearLenMaterial, y } = useYearsLogic(years);

  return (
    <a.group position-y={y}>
      {years && (
        <>
          {Object.keys(years).map((yKey) => (
            <group key={yKey} ref={addToRefs}>
              <mesh userData={yKey} material={yearLenMaterial} />
            </group>
          ))}

          {Object.entries(years).map(([yKey, yVal], yearIndex) => (
            <YearGroup
              key={`${yKey}_${yearIndex}`}
              yearName={yKey}
              yStartPos={yVal.yStart}
              yearLength={yVal.length}
              yearEvents={yVal.events}
              yIndex={yearIndex}
              isLast={yearIndex === 0}
              yearLenMaterial={yearLenMaterial}
            />
          ))}
        </>
      )}

      <EventHoverActiveWrap />
      <PopupWrap />
    </a.group>
  );
};

// XXX: Doesn't need memo
export default Years;
