import { useTransition, useSpring } from '@react-spring/three';
import {
  ShaderMaterial,
  PlaneBufferGeometry,
  Vector3,
  Color,
  EdgesGeometry,
} from 'three';

import { hexToGL } from '../../../helpers/hexToGl';
import { LANE_HEIGHT } from '../../../config/GLOBAL_CONFIG';
import leftControlsPlaneShader from '../../../shaders/leftControlsPlaneShader';
import useGlobalState from '../../../store/globalState';

const colorArr = hexToGL(Color, '#ffffff');

const getMaterial = (opacity) => {
  return new ShaderMaterial({
    transparent: true,
    uniforms: {
      u_color: {
        value: new Vector3(colorArr[0], colorArr[1], colorArr[2]),
      },
      u_opacity: { value: opacity },
    },
    fragmentShader: leftControlsPlaneShader,
  });
};

const geometry = new PlaneBufferGeometry(0.66, LANE_HEIGHT * 6);
const edgesGeometry = new EdgesGeometry(geometry);

const useLeftControlsLogic = () => {
  const machineOpen = useGlobalState((state) => state.machineOpen);

  const machinePanelTransition = useTransition(machineOpen, {
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
    delay: machineOpen ? 280 : 0,
    config: {
      tension: 330,
    },
  });

  const ORIGINAL_HEIGHT = 0.18;
  const TOP = 0.42;
  const SCALE = 5.5;

  const lineTransition = useSpring({
    posY: machineOpen
      ? TOP + (ORIGINAL_HEIGHT * SCALE - ORIGINAL_HEIGHT) * 0.5
      : TOP,
    scaleY: machineOpen ? SCALE : 1,
    delay: machineOpen ? 130 : 60,
    config: {
      tension: 265,
    },
  });

  return {
    machinePanelTransition,
    lineTransition,
    getMaterial,
    geometry,
    edgesGeometry,
  };
};

export { useLeftControlsLogic };
