import { Suspense } from 'react';
import { useTransition } from '@react-spring/web';

import App from './App';
import LoadingScreen from './components/HTML/LoadingScreen/LoadingScreen';

const AppWrap = () => {
  const loaderTransition = useTransition(true, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <Suspense fallback={<LoadingScreen loaderTransition={loaderTransition} />}>
      <App />
    </Suspense>
  );
};

export default AppWrap;
