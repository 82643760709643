const leftControlsPlaneShader = `
  uniform vec3 u_color;
  uniform float u_opacity;
  void main() {
      gl_FragColor = vec4(u_color, u_opacity);
      gl_FragColor.a *= pow(u_opacity - pow(gl_FragCoord.z, 300.), 2.);
  }
`;

export default leftControlsPlaneShader;
