import { useEffect, memo } from 'react';
import { a, useSpring } from '@react-spring/three';

import {
  GAP,
  LANE_WIDTH,
  LANE_HEIGHT,
  BORDER_WIDTH,
  LABEL_HEIGHT,
  LANES_ROT_X,
} from '../../../config/GLOBAL_CONFIG';
import Plane from '../../UiHelpers/Plane/Plane.jsx';
import Label from '../Label/Label.jsx';
import useGlobalState from '../../../store/globalState';

const getFriction = (i) => {
  let fr = 20;
  if (i === 1 || i === 3) {
    fr = 40;
  }
  if (i === 0 || i === 4) {
    fr = 60;
  }
  return fr;
};

const Lane = ({
  posX,
  color,
  border = [1, 0],
  text = false,
  laneIndex,
  labelRotation,
}) => {
  const updateLanesLoadedCount = useGlobalState(
    (state) => state.updateLanesLoadedCount
  );

  const laneSpring = useSpring({
    from: { opacity: 0, scale: 0, positionY: 0.5 },
    to: { opacity: 1, scale: 1, positionY: 0 },
    config: {
      friction: getFriction(laneIndex),
    },
  });

  useEffect(() => {
    updateLanesLoadedCount();
  }, [updateLanesLoadedCount]);

  return (
    <a.group position-y={laneSpring.positionY} opacity={laneSpring.opacity}>
      {border[0] ? (
        <Plane
          posX={posX - (LANE_WIDTH - GAP) / 2 + (BORDER_WIDTH * border[0]) / 2}
          width={BORDER_WIDTH * border[0]}
          color="#560332"
        />
      ) : null}
      {border[1] ? (
        <Plane
          posX={posX + (LANE_WIDTH - GAP) / 2 - (BORDER_WIDTH * border[1]) / 2}
          width={BORDER_WIDTH * border[1]}
          color="#560332"
        />
      ) : null}

      <Label
        posX={posX}
        posY={
          LANE_HEIGHT / -2 - LABEL_HEIGHT / 2 + LABEL_HEIGHT * LANES_ROT_X * 0.3
        }
        color={color}
        height={LABEL_HEIGHT}
        rotation={labelRotation}
        text={text}
      />
    </a.group>
  );
};

export default memo(Lane);
