import { PlaneBufferGeometry, ShaderMaterial } from 'three';
import faceCameraVertexShader from '../../../shaders/faceCameraVertexShader';

const yearPointerGeometryTop = new PlaneBufferGeometry(0.025, 0.67);
const yearPointerGeometryBtm = new PlaneBufferGeometry(0.2, 0.025);

const yearPointerMaterial = new ShaderMaterial({
  depthWrite: false,
  vertexShader: faceCameraVertexShader,
  fragmentShader: `
    void main() {
      gl_FragColor = vec4(1.,0.89,0.839, 1.);
    }
  `,
});

const YearPointer = () => {
  return (
    <group position={[0.055, 0, 0]}>
      <mesh
        userData="lineTop"
        geometry={yearPointerGeometryTop}
        material={yearPointerMaterial}
        position={[0, -0.95, 0]}
      />
      <mesh
        userData="lineBtm"
        geometry={yearPointerGeometryBtm}
        material={yearPointerMaterial}
        position={[0.12, -1.41, 0]}
      />
    </group>
  );
};

export default YearPointer;
