import { useEffect } from 'react';

import { LANES } from '../../../config/GLOBAL_CONFIG';

import Panel from '../../UiHelpers/Panel/Panel';
import { hexToRgb } from '../../../helpers/hexToRgb';

import './Popup.scss';
import PopupDesc from './PopupDesc';
import { usePopupLogic } from './usePopupLogic';
import PopupImage from './PopupImage';

const closePopupOffset = 1.6;

const Popup = ({ activeEvent = null, transitionStyles }) => {
  const { allEvents, handleClosePopup, familiesColors } = usePopupLogic();

  const [year, postID, eventIndex] = activeEvent.eventID.split('_');
  const eventData = allEvents[+year].events.find(
    (eventObj) =>
      eventObj.ID === +postID && eventObj.event.index === +eventIndex
  );

  const popupPosY = activeEvent.pinPopupPos[1];

  const {
    family_id,
    permalink,
    image,
    type,
    event: { title, description },
  } = eventData;

  let activeLaneCodeName = LANES.find((lane) => lane.family.id === family_id)
    .family.code_name;

  let activeLaneColor = familiesColors[activeLaneCodeName];

  let activeLaneColorRGB = hexToRgb(activeLaneColor);

  const PopupBody = (
    <div className="event-popup__body">
      <h4>{title}</h4>
      {description || image ? (
        <div className="event-popup__body__content">
          {description ? <PopupDesc description={description} /> : null}
          {image ? <PopupImage src={image} alt={title} /> : null}
        </div>
      ) : null}
      <a href={permalink} target="_blank" rel="noopener noreferrer">
        Read More
      </a>
    </div>
  );

  useEffect(() => {
    let reqFrame;
    let newSessY = null;
    const fn = () => {
      newSessY = +sessionStorage.getItem('y');

      const shouldClosePopupTop =
        popupPosY + closePopupOffset >= (newSessY + closePopupOffset - 20) * -1;
      const shouldRunRafTop =
        popupPosY + closePopupOffset < (newSessY + closePopupOffset - 20) * -1;

      const shouldClosePopupBtm =
        popupPosY + closePopupOffset <= (newSessY + closePopupOffset) * -1;
      const shouldRunRafBtm =
        popupPosY + closePopupOffset > (newSessY + closePopupOffset) * -1;

      if (shouldClosePopupBtm || shouldClosePopupTop) {
        handleClosePopup();
        cancelAnimationFrame(reqFrame);
      }
      if (shouldRunRafBtm && shouldRunRafTop) {
        reqFrame = requestAnimationFrame(fn);
      }
    };
    fn();

    return () => {
      // disable req
      cancelAnimationFrame(reqFrame);
    };
  });

  return (
    <Panel
      transitionStyles={transitionStyles}
      onCloseBtnClick={handleClosePopup}
      className={`event-popup event-popup--${type}`}
      bgColorHEX={activeLaneColor}
      bgColor={activeLaneColorRGB}
      opacity={0.7}
      width="300px"
    >
      {PopupBody}
    </Panel>
  );
};

export default Popup;
