const activeEventFragmentShader = `
    uniform sampler2D u_texture;
    varying vec2 vUv;

    void main() {
        vec4 color = texture2D(u_texture, vUv);
        float opacityRange = .39;
        float inc = 1.;
        float incOp = 1.;
        float opacityMultiplier = 1.;

        inc *= pow( gl_FragCoord.z, 20.0 );
        incOp *= pow( gl_FragCoord.z, 10.0 );

        if( inc > opacityRange) opacityMultiplier = color.w * 1.;
        if( inc < opacityRange) opacityMultiplier = color.w * pow(inc+incOp, 30.);
        gl_FragColor = vec4(color.x, color.y, color.z, opacityMultiplier);
    }
`;

export default activeEventFragmentShader;
