// TODO: change this when deployed
// const REST_API_BASE = 'http://localhost/dataiku/wp-json/wp/v2';
// const REST_API_BASE = 'https://dataikuhods-dev.bigdropinc.net/wp-json/wp/v2';
const REST_API_BASE = 'https://www.historyofdatascience.com/wp-json/wp/v2';

const LANES = [
  {
    posX: -2,
    border: [1, 0],
    family: {
      id: 5,
      label: 'Mathematics',
      code_name: 'mathematics',
      centerPosX: -1.9,
    },
  },
  {
    posX: -1,
    border: [0.5, 0],
    family: {
      id: 6,
      label: 'Machine Learning',
      code_name: 'machine_learning',
      centerPosX: -0.9,
    },
  },
  {
    posX: 0,
    border: [0.3, 0.3],
    family: {
      id: 7,
      label: 'Computer Science',
      code_name: 'computer_science',
      centerPosX: 0,
    },
  },
  {
    posX: 1,
    border: [0, 0.5],
    family: {
      id: 8,
      label: 'Applications',
      code_name: 'applications',
      centerPosX: 0.9,
    },
  },
  {
    posX: 2,
    border: [0, 1],
    family: {
      id: 4,
      label: 'Data Science Icons',
      code_name: 'data_science_icons',
      centerPosX: 1.9,
    },
  },
];

// SPACE BETWEEN FIRST PIN AND YEAR START AND LAST PIN AND YEAR END,
// SO PIN DOESN'T GET PLACED ON TOP OF THE LINE
const PIN_X_RAND_OFFSET = 0.3;

// CONTROLS HOW FAR FROM THE THE LABEL YEAR GROUPS START
const OFFSET_START = 2.3;
const PIN_BODY_HEIGHT = 0.25;
const ALIGN_EVENTS_IN_LANE = true;

const getPinTypeAndRadius = (categoryId) => {
  return {
    cat1: ['square', 0.25],
    cat2: ['circle', 0.16],
    cat3: ['rhombus', 0.25],
  }[categoryId];
};

const createfiberCompsConfig = () => {
  const LANE_WIDTH = 1;
  const LANE_HEIGHT = 5.5;
  // const LANE_HEIGHT = 38.5;

  // -1.6 max
  const LANES_ROT_X = 1.18;
  const BORDER_WIDTH = 0.1;
  const GAP = 0.04;
  const LABEL_HEIGHT = 0.15;
  const SPACE_BETWEEN_EVENTS = 1;

  // YEAR LABEL
  const YEAR_LABEL_PLANE_WIDTH = LANE_WIDTH * 5 + GAP * 4 + 0.2;
  const YEAR_LABEL_PLANE_HEIGHT = LABEL_HEIGHT;

  return {
    LANE_WIDTH,
    LANE_HEIGHT,
    LANES_ROT_X,
    BORDER_WIDTH,
    GAP,
    LABEL_HEIGHT,
    SPACE_BETWEEN_EVENTS,
    YEAR_LABEL_PLANE_WIDTH,
    YEAR_LABEL_PLANE_HEIGHT,
  };
};

const {
  LANE_WIDTH,
  LANE_HEIGHT,
  LANES_ROT_X,
  BORDER_WIDTH,
  GAP,
  LABEL_HEIGHT,
  SPACE_BETWEEN_EVENTS,
  YEAR_LABEL_PLANE_WIDTH,
  YEAR_LABEL_PLANE_HEIGHT,
} = createfiberCompsConfig();

export {
  LANE_WIDTH,
  LANE_HEIGHT,
  LANES_ROT_X,
  BORDER_WIDTH,
  GAP,
  LABEL_HEIGHT,
  SPACE_BETWEEN_EVENTS,
  YEAR_LABEL_PLANE_WIDTH,
  YEAR_LABEL_PLANE_HEIGHT,
  REST_API_BASE,
  LANES,
  PIN_X_RAND_OFFSET,
  OFFSET_START,
  PIN_BODY_HEIGHT,
  ALIGN_EVENTS_IN_LANE,
  getPinTypeAndRadius,
};
