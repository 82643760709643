import { useEffect, useState, useCallback, memo } from 'react';
import { createPortal } from 'react-dom';
import { Stage, Layer } from 'react-konva';
import { animated, SpringContext, useSpring } from '@react-spring/konva';

import { useSprings } from '@react-spring/three';

import { randomIntFromInterval } from '../../../helpers/randomIntFromInterval';
import { map } from '../../../helpers/map';

const segRadius = 2,
  numberOfRings = 100,
  numberOfClusters = 5,
  minimumSeg = 20,
  segmentsRepetition = 2,
  startRadius = 120,
  spaceBetweenRings = 6,
  spaceBetweenClusters = 0,
  offsetSegmentsByIter = true,
  offsetSegmentsByRandomI = true,
  minimumSegmentRadius = 0.9,
  canvasSizeMult = 3,
  offsetY = 0.3;

const CanvasCircles = () => {
  const [clusters, setClusters] = useState([]);
  const [stageSizeW, setStageSizeW] = useState(window.innerWidth);
  const [stageSizeH, setStageSizeH] = useState(window.innerHeight);
  const startDimensions = { width: 1920, height: 1080 };

  const initialSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      friction: 300,
    },
  });

  console.log('Circles Rerendered -------------------------');

  const calcOffSCanvas = useCallback(() => {
    setClusters([]);
    const canvasOffS = document.createElement('canvas');
    const ctx = canvasOffS.getContext('2d');
    canvasOffS.width = startDimensions.width * canvasSizeMult;
    canvasOffS.height = startDimensions.height * canvasSizeMult;

    const createClusterCanvas = (canvas) => {
      const clusterCanvasOffScreen = document.createElement('canvas');
      const ctxClOff = clusterCanvasOffScreen.getContext('2d');
      clusterCanvasOffScreen.width = startDimensions.width * canvasSizeMult;
      clusterCanvasOffScreen.height = startDimensions.height * canvasSizeMult;
      ctxClOff.clearRect(
        0,
        0,
        clusterCanvasOffScreen.width,
        clusterCanvasOffScreen.height
      );
      ctxClOff.save();
      ctxClOff.translate(
        clusterCanvasOffScreen.width * 0.5,
        clusterCanvasOffScreen.height * 0.5
      );
      ctxClOff.drawImage(
        canvas,
        -clusterCanvasOffScreen.width * 0.5,
        -clusterCanvasOffScreen.height * 0.5,
        clusterCanvasOffScreen.width,
        clusterCanvasOffScreen.height
      );
      ctxClOff.restore();

      setClusters((prevClusters) => [
        ...prevClusters,
        [clusterCanvasOffScreen],
      ]);
    };

    let clusterToPrint = 1;
    let spaceBetweenRingsC;
    let ringRadiusIncrement = 0;
    let startRingsPerCluster = Math.ceil(
      (numberOfRings / numberOfClusters) * 0.5
    );
    let ringsPerCluster;
    let ringsPerClusterInc = 0;

    spaceBetweenRings === 0
      ? (spaceBetweenRingsC = 1)
      : (spaceBetweenRingsC = spaceBetweenRings);

    //Clusters
    for (let cluster = 1; cluster < numberOfClusters + 1; cluster++) {
      const segmentsRepetitionC = segmentsRepetition + cluster - 1;
      const steps = minimumSeg + (cluster - 1) * 30;
      const angPortion = +((Math.PI * 2) / steps).toFixed(4);
      const startingSegmentRadius = Math.floor(segRadius + (cluster - 1) * 0.5);

      if (cluster === 1) {
        ringsPerCluster = startRingsPerCluster;
      } else if (cluster !== numberOfClusters) {
        ringsPerCluster = Math.ceil(
          ((numberOfRings - ringsPerClusterInc) /
            (numberOfClusters - cluster)) *
            0.5
        );
      } else if (cluster === numberOfClusters) {
        ringsPerCluster = numberOfRings - ringsPerClusterInc;
      }
      ringsPerClusterInc += Math.ceil(ringsPerCluster);
      ctx.clearRect(0, 0, canvasOffS.width, canvasOffS.height);

      //Rings
      for (let ring = 1; ring <= ringsPerCluster; ring++) {
        let segmentRadius = startingSegmentRadius;
        const segmentRadiusDecrement = +(
          (segmentRadius - minimumSegmentRadius) /
          (steps / segmentsRepetitionC)
        ).toFixed(3);
        const positionOffset =
          (offsetSegmentsByIter ? ring * 0.08 : 0) +
          +(
            (offsetSegmentsByRandomI
              ? randomIntFromInterval(Math.PI * 0.1, Math.PI * 0.2, false)
              : 0) * 0.6
          ).toFixed(3);
        const ringRadius =
          startRadius +
          ringRadiusIncrement +
          spaceBetweenClusters * (cluster - 1);
        ringRadiusIncrement += startingSegmentRadius * 2 + spaceBetweenRingsC;

        //Segments
        for (let segment = 0; segment <= steps; segment++) {
          if (clusterToPrint === cluster) {
            if (segmentRadius <= minimumSegmentRadius) {
              segmentRadius = startingSegmentRadius;
            }
            const opacity = +map(
              segmentRadius,
              1,
              startingSegmentRadius,
              0,
              0.5
            ).toFixed(3);
            const position = +(
              (2 * Math.PI * segment) / steps +
              positionOffset +
              cluster
            ).toFixed(3);
            const x = +(ringRadius * Math.cos(position)).toFixed(1);
            const y = +(ringRadius * Math.sin(position)).toFixed(1);

            ctx.save();
            ctx.translate(canvasOffS.width * 0.5, canvasOffS.height * 0.5);
            ctx.rotate(cluster * 0.1 + 0.1);

            if (Math.floor(segmentRadius) === startingSegmentRadius) {
              ctx.beginPath();
              ctx.fillStyle = `rgba(255,255,255,${opacity})`;
              ctx.arc(x, y, segmentRadius, 0, Math.PI * 2);
              ctx.fill();
            }

            if (segment !== steps) {
              ctx.beginPath();
              ctx.strokeStyle = `rgba(255,255,255,${opacity * 0.5})`;
              ctx.lineWidth = segmentRadius * 2;
              ctx.lineCap = 'round';
              ctx.arc(
                0,
                0,
                ringRadius,
                position,
                +(position + angPortion).toFixed(3)
              );
              ctx.stroke();
            }

            ctx.restore();
            if (segmentsRepetitionC !== 0) {
              segmentRadius -= segmentRadiusDecrement;
            }
          }
        }
      }

      clusterToPrint += 1;
      createClusterCanvas(canvasOffS);
    }
  }, [startDimensions.height, startDimensions.width]);

  useEffect(() => {
    calcOffSCanvas();

    const setStageSize = () => {
      setStageSizeW(window.innerWidth);
      setStageSizeH(window.innerHeight);
    };

    window.addEventListener('resize', setStageSize);

    return () => {
      window.removeEventListener('resize', setStageSize);
    };
  }, [calcOffSCanvas]);

  const [springs, api] = useSprings(numberOfClusters, (i) => ({
    y: 0,
    config: {
      precision: 0.005,
      friction: 30 + (i + 1) * 20,
    },
  }));

  const runSprings = useCallback(
    (y) => {
      api.start(() => {
        return {
          y: -y,
        };
      });
    },
    [api]
  );

  const runCircles = (newY) => {
    runSprings(newY * 10);
  };

  window.runCircles = runCircles;

  return createPortal(
    <>
      {clusters.length === numberOfClusters ? (
        <Stage width={stageSizeW} height={stageSizeH}>
          <Layer>
            <animated.Group opacity={initialSpring.opacity}>
              {springs
                ? springs.map(({ y }, i) => (
                    <SpringContext key={i} cancel={true}>
                      <animated.Image
                        image={clusters[i][0]}
                        width={startDimensions.width * canvasSizeMult}
                        height={startDimensions.height * canvasSizeMult}
                        x={stageSizeW * 0.5}
                        y={stageSizeH * 0.5 - stageSizeH * offsetY}
                        offsetX={startDimensions.width * canvasSizeMult * 0.5}
                        offsetY={startDimensions.height * canvasSizeMult * 0.5}
                        rotation={y}
                      />
                    </SpringContext>
                  ))
                : null}
            </animated.Group>
          </Layer>
        </Stage>
      ) : null}
    </>,
    document.getElementById('circlesPortal')
  );
};

export default memo(CanvasCircles);
