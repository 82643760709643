import { useEffect, useRef } from 'react';

import { animated } from '@react-spring/web';
import CloseIcon from '../../../assets/svg/CloseIcon';

import './Panel.scss';

const Panel = ({
  children,
  transitionStyles,
  onCloseBtnClick,
  className,
  bgColor = '255, 227, 214',
  opacity = 0.2,
  width = '300px',
}) => {
  const mainWrapRef = useRef();

  useEffect(() => {
    mainWrapRef.current.style.setProperty('--bgColor', bgColor);

    mainWrapRef.current.style.setProperty('--opacity', opacity);
    mainWrapRef.current.style.setProperty('--width', width);
  }, [bgColor, opacity, width]);

  return (
    <animated.div
      ref={mainWrapRef}
      className={`panel ${className}`}
      style={transitionStyles}
    >
      <button className="panel__close" onClick={onCloseBtnClick}>
        <CloseIcon />
      </button>

      <div className="panel__main">{children}</div>
    </animated.div>
  );
};

export default Panel;
