import Tracks from '../Tracks/Tracks';
import './TracksWrap.css';

const TracksWrap = () => {
  return (
    <div className="tracks-wrap">
      <Tracks />
    </div>
  );
};

export default TracksWrap;
