import useGlobalState from '../../../store/globalState';

const useEventLogic = (eventData) => {
  const eventID = eventData.combined_event_id,
    type = eventData.type,
    categoryKey = eventData.timeline_category.value;

  const allCategories = useGlobalState((state) => state.allCategories);

  const togglePopup = useGlobalState((state) => state.togglePopup);
  const togglePinHovered = useGlobalState((state) => state.togglePinHovered);
  const addPinActive = useGlobalState((state) => state.addPinActive);
  const clearPinsActives = useGlobalState((state) => state.clearPinsActives);
  const eventPinShaderMaterial = useGlobalState(
    (state) => state.eventPinShaderMaterial
  );

  const activeCategoriesKeysArr = Object.values(allCategories)
    .filter((cat) => cat.active)
    .map((cat) => cat.key);

  const isActiveCategory = activeCategoriesKeysArr.includes(categoryKey);
  const eventOpacity = isActiveCategory ? 1 : 0.25;

  const eventPinMaterial = eventPinShaderMaterial.clone();
  eventPinMaterial.onBeforeCompile = (shader) => {
    shader.uniforms.u_pinOpacity.value = eventOpacity;
  };

  const pinRotation = [0, 0, type === 'rhombus' ? Math.PI / 1.34 : 0];

  const handleSetActiveEvent = () => {
    if (!isActiveCategory) return;
    window.sessionStorage.setItem('pinHovered', true);
    togglePinHovered(true);
    togglePopup(eventID);
  };

  const handleHover = (over = false) => {
    if (!isActiveCategory) return;
    if (over) {
      window.sessionStorage.setItem('pinHovered', true);
      togglePinHovered(true);
      addPinActive(eventID);
    } else {
      window.sessionStorage.removeItem('pinHovered');
      togglePinHovered(false);
      clearPinsActives();
    }
  };

  return {
    pinRotation,
    eventPinMaterial,
    handleHover,
    handleSetActiveEvent,
  };
};

export { useEventLogic };
